import React, { useState, useContext, useEffect, useRef } from "react";
import { reduce, max } from "lodash";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import HeaderMenu from "./HeaderMenu";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { deepOrange } from "@material-ui/core/colors";
import PersonIcon from "@material-ui/icons/Person";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DropDownMenu from "./DropDownMenu";
import AlertContext from "../../contexts/alert-context";
import { ReactComponent as SensocoLogo } from "../../assets/../assets/sensoco-logo-header.svg";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    height: 86,
    padding: theme.spacing(2, 8, 1, 8),
  },
  title: {
    fontSize: "40px",
    fontWeight: "900",
    marginLeft: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  buttons: {
    marginLeft: "auto",
    color: "white",
    cursor: "pointer",
  },
  root: {
    background: "black",
    paddingTop: theme.spacing(2),
  },
  iconButton: {
    width: 35,
  },
  smallIconButton: {
    marginTop: theme.spacing(1.5),
    width: 10,
    height: 10,
  },
  smallIcon: {
    width: 16,
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    marginRight: theme.spacing(2),
  },
  name: {
    marginRight: theme.spacing(8),
  },
  headerMenu: {
    marginLeft: "auto",
  },
}));

const Header = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1100px)");
  const [open, setOpen] = useState(false);
  const alertContext = useContext(AlertContext);
  const [showBadge, setShowBadge] = useState(false);
  const { isSmsSent, isPredictiveAlert } = alertContext;
  const anchor = useRef(null);

  useEffect(() => {
    setTimeout(() => setShowBadge(true), 20000);
  });

  const toggleOpen = () => {
    setOpen(!open);
  };

  const activeAlerts = reduce(
    [!isSmsSent, isPredictiveAlert],
    (memo, e) => {
      if (e) {
        memo += 1;
      }
      return memo;
    },
    0
  );
  return (
    <div>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          {matches && <SensocoLogo />}
          <div className={classes.headerMenu}>
            <HeaderMenu />
          </div>
          <div onClick={toggleOpen} ref={anchor} className={classes.buttons}>
            <Grid alignItems="center" wrap="nowrap" container>
              {(showBadge && activeAlerts > 0) || activeAlerts === 0 ? (
                <Badge
                  color="secondary"
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={max([activeAlerts, 1])}
                >
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </Badge>
              ) : (
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              )}
              <Typography className={classes.name}>Demo User</Typography>
              <IconButton
                aria-label="search"
                color="inherit"
                className={classes.smallIconButton}
              >
                <KeyboardArrowDownIcon className={classes.smallIcon} />
              </IconButton>
              <DropDownMenu
                anchorEl={anchor}
                toggleOpen={toggleOpen}
                open={open}
              />
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
