import React from "react";

const NotFoundPage = () => {
  return (
    <p
      style={{
        marginTop: 100,
        textAlign: "center",
        width: "100%",
        color: "white",
      }}
    >
      404 NOT FOUND
    </p>
  );
};

export default NotFoundPage;
