import React, { useContext, useState, useRef, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Collapse,
  Grid,
  TextField,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { sendSms, logGAEvent } from "../../firebase";
import AlertContext from "../../contexts/alert-context";
import ContainedButton from "../../themes/ContainedButton";
import SnackbarUtils from "../../utils/snackBarUtils";

const MAP_LINK = "https://goo.gl/maps/Es3c8eYk3p893ZGT9";

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: "0px",
    right: "50px",
    position: "fixed",
    paddingTop: "14px",
    paddingBottom: "14px",
    paddingRight: "10px",
    paddingLeft: "10px",
    zIndex: 1000,
    height: "280px",
    width: "390px",
  },
  field: {
    marginBottom: "10px",
  },
  input: {
    fontSize: "13px",
    color: "white",
  },
  headerText: {
    fontSize: 16,
    textTransform: "uppercase",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "4px",
  },
  confirmInfo: {
    color: "#808080",
  },
  smsGrid: {
    marginTop: "20px",
  },
  confirmWrapper: {
    marginLeft: "4px",
  },
  button: { marginRight: "4px", marginTop: "8px" },
}));

export default function SmsDialog(props) {
  const classes = useStyles();
  const { success, error, closeSnackbar } = SnackbarUtils;
  const phoneInputRef = useRef(null);
  const { handleSendClose, problemTruck } = props;
  const { driverName } = problemTruck;
  const { isSmsDialogFocus, setIsSmsDialogFocus } = useContext(AlertContext);
  const [phone, setPhone] = useState("");
  const handleClose = () => {
    setIsSmsDialogFocus(false);
  };

  useEffect(() => {
    phoneInputRef?.current?.focus();
  }, [isSmsDialogFocus]);

  const handleSubmit = async () => {
    try {
      if (phone) {
        await sendSms({ phone, link: MAP_LINK });
      }
      closeSnackbar();
      success("SMS message sucessfully sent!");
      handleSendClose();
    } catch (ex) {
      error("Error sending SMS confirmation", {
        persist: true,
      });
    } finally {
      logGAEvent("send-sms", {});
    }
  };

  return (
    <Collapse in={isSmsDialogFocus}>
      <Paper elevation={3} className={classes.root}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h6"
              align="left"
              color="primary"
              className={classes.headerText}
            >
              New Message
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              className={classes.field}
              fullWidth
              disabled
              variant="outlined"
              size="small"
              value={driverName}
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
            />
            <TextField
              fullWidth
              multiline
              disabled
              variant="outlined"
              size="small"
              InputProps={{
                classes: {
                  input: classes.input,
                },
              }}
              value={`Your vehicle is predicted to have a critical part failure within the next 3 operating hours. The tractor is being replaced by Vehicle 893. Please proceed to the nearest depot: ${MAP_LINK}`}
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.smsGrid}
          >
            <Grid item>
              <div className={classes.confirmWrapper}>
                <Typography
                  className={classes.confirmInfo}
                  variant="body2"
                  align="left"
                >
                  A confirmation SMS will be sent to
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  InputLabelProps={{ style: { fontSize: 20 } }}
                  className={classes.field}
                  id="phone"
                  inputRef={phoneInputRef}
                  placeholder="Enter phone number"
                  onChange={(event) => setPhone(event.target.value)}
                  value={phone}
                />
              </div>
            </Grid>
            <Grid>
              <ContainedButton
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
              >
                Send
              </ContainedButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      ;
    </Collapse>
  );
}
