import React, { useMemo } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import ProgressIcon from "../shared/ProgressIcon";

import useRunInlineQuery from "../../hooks/use-run-inline-query";
import lookMLToQuery from "../../utils/lookMLToQuery";

import SparkLine from "../visualizations/SparkLine";

import MILES_DRIVEN_ML from "./queries/miles-driven.yaml";
import milesDrivenParser from "./parsers/miles-driven-parser";

import TRIP_TOTAL_REVENUE_ML from "./queries/trip-total-revenue.yaml";
import tripTotalRevenueParser from "./parsers/trip-total-revenue-parser";

import TOTAL_DELIVERIES_ML from "./queries/total-deliveries.yaml";
import totalDeliveriesParser from "./parsers/total-deliveries-parser";

const MILES_DRIVEN_QUERY = lookMLToQuery(MILES_DRIVEN_ML);
const TRIP_TOTAL_REVENUE_QUERY = lookMLToQuery(TRIP_TOTAL_REVENUE_ML);
const TOTAL_DELIVERIES_QUERY = lookMLToQuery(TOTAL_DELIVERIES_ML);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "100px",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
  },
  keyMetric: {
    fontWeight: 900,
    color: "white",
  },
  keyMetricLoading: {
    fontWeight: 900,
    color: theme.palette.text.secondary,
  },
  box: {
    background: theme.palette.primary.main,
  },
  heroContent: {
    padding: theme.spacing(0, 0, 12, 0),
    marginTop: "36px",
    zIndex: "100",
  },
  heroTitle: {
    fontWeight: 100,
  },
  heroPara: {
    fontSize: 16,
  },
  root: {
    width: 500,
    background: "none",
    border: "none",
    color: theme.palette.primary.main,
    paddingRight: 250,
  },
  description: {
    fontSize: 12,
  },
  gridContain: {
    marginBottom: theme.spacing(16),
  },
  sparkLineVis: {
    width: "100%",
  },
}));

export default function FleetOverview() {
  const milesDrivenQueryState = useRunInlineQuery(MILES_DRIVEN_QUERY);
  const tripTotalRevenueState = useRunInlineQuery(TRIP_TOTAL_REVENUE_QUERY);
  const totalDeliveriesState = useRunInlineQuery(TOTAL_DELIVERIES_QUERY);

  const milesDrivenData = useMemo(
    () => milesDrivenParser(milesDrivenQueryState.queryResults),
    [milesDrivenQueryState.queryResults]
  );

  const tripTotalRevenueData = useMemo(
    () => tripTotalRevenueParser(tripTotalRevenueState.queryResults),
    [tripTotalRevenueState.queryResults]
  );

  const totalDeliveriesData = useMemo(
    () => totalDeliveriesParser(totalDeliveriesState.queryResults),
    [totalDeliveriesState.queryResults]
  );

  const classes = useStyles();

  return (
    <Container maxWidth="md" component="main" className={classes.container}>
      <CssBaseline />

      {/* Hero unit */}
      <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Typography
          variant="h3"
          align="left"
          color="textSecondary"
          className={classes.heroTitle}
          gutterBottom
        >
          Fleet Overview
        </Typography>
        <Typography
          className={classes.heroPara}
          align="left"
          color="textSecondary"
        >
          We have 43 trucks running this month, 18 more than this time last
          year. You can see here we also have a breakdown based on months and
          cities. This is great for comparing running costs across different
          locations and to easily identify trends in fuel costs. In the past 6
          weeks there’s been a 38% increase in miles driven which has driven
          fuel costs up 29%.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main" className={classes.gridContain}>
        <Grid container spacing={10} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.root}>
              <CardContent>
                <div className={classes.cardPricing}>
                  {milesDrivenData ? (
                    <Typography
                      className={
                        milesDrivenQueryState.isLoading
                          ? classes.keyMetricLoading
                          : classes.keyMetric
                      }
                      variant="h3"
                      color="textSecondary"
                    >
                      {milesDrivenData.total}
                    </Typography>
                  ) : (
                    <ProgressIcon isError={milesDrivenQueryState.error} />
                  )}
                </div>
                <Typography
                  className={classes.description}
                  variant="subtitle1"
                  align="center"
                >
                  Miles Driven
                </Typography>
                <div className={classes.sparkLineVis}>
                  {milesDrivenData && !milesDrivenQueryState.isLoading ? (
                    <SparkLine data={milesDrivenData} height={36} />
                  ) : (
                    <ProgressIcon isError={milesDrivenQueryState.error} />
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.root}>
              <CardContent>
                <div className={classes.cardPricing}>
                  {tripTotalRevenueData ? (
                    <Typography
                      className={
                        tripTotalRevenueState.isLoading
                          ? classes.keyMetricLoading
                          : classes.keyMetric
                      }
                      variant="h3"
                      color="textSecondary"
                    >
                      {tripTotalRevenueData.total}
                    </Typography>
                  ) : (
                    <ProgressIcon isError={tripTotalRevenueState.error} />
                  )}
                </div>
                <Typography
                  className={classes.description}
                  variant="subtitle1"
                  align="center"
                >
                  Total Revenue
                </Typography>
                <div className={classes.sparkLineVis}>
                  {tripTotalRevenueData && !tripTotalRevenueState.isLoading ? (
                    <SparkLine data={tripTotalRevenueData} height={36} />
                  ) : (
                    <ProgressIcon isError={tripTotalRevenueState.error} />
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.root}>
              <CardContent>
                <div className={classes.cardPricing}>
                  {totalDeliveriesData ? (
                    <Typography
                      className={
                        totalDeliveriesState.isLoading
                          ? classes.keyMetricLoading
                          : classes.keyMetric
                      }
                      variant="h3"
                      color="textSecondary"
                    >
                      {totalDeliveriesData.total}
                    </Typography>
                  ) : (
                    <ProgressIcon isError={totalDeliveriesState.error} />
                  )}
                </div>
                <Typography
                  className={classes.description}
                  variant="subtitle1"
                  align="center"
                >
                  Total Deliveries
                </Typography>
                <div className={classes.sparkLineVis}>
                  {totalDeliveriesData && !totalDeliveriesState.isLoading ? (
                    <SparkLine data={totalDeliveriesData} height={36} />
                  ) : (
                    <ProgressIcon isError={totalDeliveriesState.error} />
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
