import { map } from "lodash";

const truckTypeParser = (queryResults) => {
  if (queryResults) {
    const { data } = queryResults;
    const DIMENSION = "vehicles.truck_type";
    return {
      dimension: DIMENSION,
      payload: map(data, (e) => {
        return {
          label: e[DIMENSION].value,
          value: e["trips.count"].value,
        };
      }),
    };
  } else {
    return null;
  }
};

export default truckTypeParser;
