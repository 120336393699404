import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { generateDemoEmbedUrl } from "../firebase";

import Footer from "./shared/Footer";

import ProgressIcon from "./shared/ProgressIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    top: "85px",
    height: "1400px",
    background: "#F6F8FA",
    paddingBottom: theme.spacing(18),
  },
}));

const ReportingPage = () => {
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const executeGetDashboard = async () => {
      try {
        const reponse = await generateDemoEmbedUrl({
          targetUrl: "/dashboards/176?theme=data_experience_demo",
        });
        setDashboardUrl(reponse.url);
        setError(null);
      } catch (ex) {
        setError(ex.message);
      }
    };
    executeGetDashboard();
  }, []);

  const classes = useStyles();

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        {dashboardUrl ? (
          <iframe
            src={dashboardUrl}
            style={{ width: "100%", height: "1400px" }}
          />
        ) : (
          <ProgressIcon isError={error} />
        )}
      </Grid>
      <Footer />
    </>
  );
};

export default ReportingPage;
