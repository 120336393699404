import React, { useEffect, useState } from "react";
import Application from "./Components/Application";
import UserProvider from "./providers/UserProvider";
import { ThemeProvider, Button } from "@material-ui/core";
import { theme } from "./themes/MUITheme";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { reducer } from "./reducers";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import ViewportContext from "./contexts/viewport-context";

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

function App() {
  const store = createStore(reducer);

  const minViewPort = 860;
  const [isViewPortTooSmall, setIsViewPortTooSmall] = useState(
    window.innerWidth < minViewPort
  );

  useEffect(() => {
    function handleResize() {
      setIsViewPortTooSmall(window.innerWidth < minViewPort);
    }

    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth, minViewPort]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <Button onClick={onClickDismiss(key)}>
                <CloseIcon />
              </Button>
            )}
            maxSnack={3}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={3000}
            iconVariant={{
              error: <ErrorOutline />,
            }}
          >
            <ViewportContext.Provider value={{ isViewPortTooSmall }}>
              <Application />
            </ViewportContext.Provider>
          </SnackbarProvider>
        </UserProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
