import { map, min, max, reduce, omit } from "lodash";
import turf from "turf";
import chroma from "chroma-js";

const routesParser = (queryResults, loopLength) => {
  if (queryResults) {
    const { data } = queryResults;
    const revenue = map(data, (e) => e["trips.total_trip_revenue"].value);

    const colorDomain = [min(revenue), max(revenue)];

    const colorScale = chroma.scale(["#FF0000", "#00AA00"]).domain(colorDomain);

    const features = reduce(
      data,
      (memo, e) => {
        const color = colorScale(e["trips.total_trip_revenue"].value).rgb();

        const geometry = JSON.parse(e["routes_complete.geometry"].value);
        const timestamps = [];
        for (let i = 0; i < geometry.coordinates.length; i++) {
          if (i === 0) {
            // simulate staggered start times
            const startTime = Math.random() * loopLength;
            timestamps.push(startTime);
          } else {
            const from = turf.point(geometry.coordinates[i - 1]);
            const to = turf.point(geometry.coordinates[i]);
            const distance = turf.distance(from, to) * 1000;
            const legDuration =
              (distance / e["routes_complete.distance"].value) *
              e["routes_complete.duration"].value;
            timestamps.push(legDuration + timestamps[i - 1]);
          }
        }

        // prepare as geojson
        const datum = {
          type: "Feature",
          color,
          properties: omit(e, "routes_complete.geometry"),
          geometry,
          timestamps,
          // eslint-disable-next-line prettier/prettier
          message: `${e["routes_complete.origin_city"].value} - ${
            e["routes_complete.dest_city"].value
            // eslint-disable-next-line prettier/prettier
          }: ${e["trips.total_trip_revenue"].rendered}`,
        };
        memo.push(datum);
        return memo;
      },
      []
    );

    return {
      type: "FeatureCollection",
      features,
      colorScale,
      loopLength,
    };
  } else {
    return {
      type: "FeatureCollection",
      features: [],
      loopLength: 0,
    };
  }
};

export default routesParser;
