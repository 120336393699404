import React, { useMemo, useContext, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import {
  makeStyles,
  IconButton,
  Container,
  Typography,
  Grid,
  Divider,
  Collapse,
  Box,
} from "@material-ui/core";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FilterVisCard from "./FilterVisCard";
import FilterVisForm from "./FilterVisForm";
import PieChart from "../visualizations/PieChart";
import ColumnChart from "../visualizations/ColumnChart";
import NoResultsFound from "../shared/NoResultsFound";

import ProgressIcon from "../shared/ProgressIcon";

import useRunInlineQuery from "../../hooks/use-run-inline-query";
import lookMLToQuery from "../../utils/lookMLToQuery";
import FilterContext from "../../contexts/filter-context";

import { logGAEvent } from "../../firebase";

import HAUL_DISTANCE_TIERS_ML from "./queries/haul-distance-tiers.yaml";
import MILEAGE_TIERS_ML from "./queries/mileage-tiers.yaml";
import TRUCK_TYPE_ML from "./queries/truck-type.yaml";
import FLEET_SUMMARY_ML from "./queries/fleet-summary.yaml";

import haulDistanceTiersParser from "./parsers/haul-distance-tiers-parser";
import mileageTiersParser from "./parsers/mileage-tiers-parser";
import truckTypeParser from "./parsers/truck-type-parser";
import fleetSummaryParser from "./parsers/fleet-summary-parser";

const HAUL_DISTANCE_QUERY = lookMLToQuery(HAUL_DISTANCE_TIERS_ML);
const MILEAGE_TIERS_QUERY = lookMLToQuery(MILEAGE_TIERS_ML);
const TRUCK_TYPE_QUERY = lookMLToQuery(TRUCK_TYPE_ML);
const FLEET_SUMMARY_QUERY = lookMLToQuery(FLEET_SUMMARY_ML);

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: 16,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(6),
    color: "white",
    cursor: "pointer",
  },
  wrapper: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  wrapperBelowFold: {
    backgroundColor: "rgba(0,0,0,0.85)",
  },
  spacer: {
    height: "0",
    transitionProperty: "height",
    transitionDuration: "0.6s",
    transitionTimingFunction: "cubic-bezier(0.075, 0.820, 0.165, 1.000)",
  },
  spacerBelowFold: {
    height: "94px",
  },
  innerContent: {
    position: "relative",
    backgroundColor: "rgba(0,0,0, 0.45)",
    borderBottom: `1px dashed ${theme.palette.primary.light}`,
    padding: theme.spacing(6, 4, 9, 4),
    transitionProperty: "padding",
    transitionDuration: "0.6s",
    transitionTimingFunction: "cubic-bezier(0.075, 0.820, 0.165, 1.000)",
    flexWrap: "nowrap",
  },
  innerContentCollapsed: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  innerContentBelowFold: {
    backgroundColor: "rgba(0,0,0,0)",
    borderBottom: "none",
  },
  unCollapsedContent: {
    opacity: 1,
  },
  collapsedContent: {
    opacity: 0,
  },
  resetButton: {
    position: "absolute",
    right: 5,
    top: 10,
  },
  expandButton: {
    position: "relative",
    top: "-2px",
    padding: "0",
    display: "none",
    cursor: "pointer",
  },
  showExpandedButton: {
    display: "inline-block",
  },
  keyMetric: {
    fontWeight: 900,
    marginRight: "5px",
  },
  keyMetricLoading: {
    fontWeight: 900,
    marginRight: "5px",
    color: theme.palette.text.secondary,
  },
  keyMetricLabel: {
    marginRight: "15px",
    textColor: "white",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  downPromptWrapper: {
    textAlign: "center",
  },
  "@keyframes downPulse": {
    "0%": {
      opacity: 0.5,
      transform: "translateY(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
  },
  downPrompt: {
    animationName: "$downPulse",
    animationDuration: "1.5s",
    animationFillMode: "both",
    animationIterationCount: "infinite",
  },
}));

const initialLayoutState = {
  loaded: false,
  collapsed: false,
  belowFold: false,
};

export default function FilterVis({ scrollEvent }) {
  const classes = useStyles();
  const initialLayoutClasses = {
    wrapper: classes.wrapper,
    spacer: classes.spacer,
    expandButton: classes.expandButton,
    container: classes.innerContent,
    expander: classes.unCollapsedContent,
  };

  const { handleSetFilter, handleClearFilters, filters } = useContext(
    FilterContext
  );
  const [layoutState, setLayoutState] = useState(initialLayoutState);
  const [layoutClasses, setLayoutClasses] = useState([initialLayoutClasses]);

  // this is to debounce vis rendering b/c map drawing is so expensive
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLayoutState({
        ...layoutState,
        loaded: true,
      });
    }, 3500);
    return () => clearTimeout(timeout);
  }, []);

  // build combined classes
  useEffect(() => {
    const c = classes;
    let newClasses = {};
    if (layoutState.belowFold && layoutState.collapsed) {
      newClasses = {
        wrapper: [c.wrapper, c.wrapperBelowFold].join(" "),
        spacer: [c.spacer, c.spacerBelowFold].join(" "),
        expandButton: [c.expandButton, c.showExpandedButton].join(" "),
        expander: [c.unCollapsedContent, c.collapsedContent].join(" "),
        container: [
          c.innerContent,
          c.innerContentCollapsed,
          c.innerContentBelowFold,
        ].join(" "),
      };
    } else if (layoutState.belowFold && !layoutState.collapsed) {
      newClasses = {
        wrapper: [c.wrapper, c.wrapperBelowFold].join(" "),
        spacer: [c.spacer, c.spacerBelowFold].join(" "),
        expandButton: [c.expandButton, c.showExpandedButton].join(" "),
        expander: [c.unCollapsedContent].join(" "),
        container: [c.innerContent, c.innerContentBelowFold].join(" "),
      };
    } else {
      newClasses = initialLayoutClasses;
    }
    setLayoutClasses(newClasses);
  }, [layoutState]);

  // handle scroll magic events
  useEffect(() => {
    // if above fold
    if (scrollEvent.progress === 0) {
      setLayoutState({
        ...layoutState,
        belowFold: false,
        collapsed: false,
      });
    }
    // if below fold
    if (scrollEvent.progress === 1) {
      setLayoutState({
        ...layoutState,
        belowFold: true,
        collapsed: true,
      });
    }
  }, [scrollEvent]);

  const haulDistanceQueryState = useRunInlineQuery(HAUL_DISTANCE_QUERY);
  const mileageTiersQueryState = useRunInlineQuery(MILEAGE_TIERS_QUERY);
  const truckTypeQueryState = useRunInlineQuery(TRUCK_TYPE_QUERY);
  const fleetSummaryQueryState = useRunInlineQuery(FLEET_SUMMARY_QUERY);

  const haulDistanceTiersData = useMemo(
    () => haulDistanceTiersParser(haulDistanceQueryState.queryResults),
    [truckTypeQueryState]
  );

  const mileageTiersData = useMemo(
    () => mileageTiersParser(mileageTiersQueryState.queryResults),
    [truckTypeQueryState]
  );

  const truckTypeData = useMemo(
    () => truckTypeParser(truckTypeQueryState.queryResults),
    [truckTypeQueryState]
  );

  const fleetSummaryData = useMemo(
    () => fleetSummaryParser(fleetSummaryQueryState.queryResults),
    [fleetSummaryQueryState]
  );

  const handleToggleCollapse = () => {
    if (!layoutState.belowFold) return;
    if (layoutState.collapsed) {
      setLayoutState({
        ...layoutState,
        collapsed: false,
      });
    } else {
      setLayoutState({
        ...layoutState,
        collapsed: true,
      });
    }
  };

  return (
    <div className={layoutClasses.wrapper}>
      <div className={layoutClasses.spacer} />
      <Container
        maxWidth="md"
        component="main"
        className={layoutClasses.container}
      >
        <IconButton
          disabled={isEmpty(filters)}
          onClick={handleClearFilters}
          aria-label="reset-filters"
          className={classes.resetButton}
          color="primary"
        >
          <RotateLeftIcon fontSize="large" />
        </IconButton>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
          wrap="nowrap"
        >
          <Typography
            onClick={handleToggleCollapse}
            variant="h6"
            align="left"
            color="textSecondary"
            className={classes.headerText}
          >
            <IconButton
              aria-label="toggle-filters"
              className={layoutClasses.expandButton}
            >
              {layoutState.collapsed ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </IconButton>
            FILTERS
          </Typography>
          <Typography
            className={
              fleetSummaryQueryState.isLoading
                ? classes.keyMetricLoading
                : classes.keyMetric
            }
            variant="h4"
          >
            {fleetSummaryData ? fleetSummaryData.active : "0"}
          </Typography>
          <Typography className={classes.keyMetricLabel} variant="subtitle1">
            Active trailers
          </Typography>
          <Typography
            className={
              fleetSummaryQueryState.isLoading
                ? classes.keyMetricLoading
                : classes.keyMetric
            }
            variant="h4"
          >
            {fleetSummaryData ? fleetSummaryData.available : "0"}
          </Typography>
          <Typography className={classes.keyMetricLabel} variant="subtitle1">
            Available trailers
          </Typography>
          <Typography
            className={
              fleetSummaryQueryState.isLoading
                ? classes.keyMetricLoading
                : classes.keyMetric
            }
            variant="h4"
          >
            {fleetSummaryData ? fleetSummaryData.drivers : "0"}
          </Typography>
          <Typography className={classes.keyMetricLabel} variant="subtitle1">
            Drivers
          </Typography>
        </Grid>
        <Collapse in={!layoutState.collapsed}>
          <Divider className={classes.divider} />
          <Grid container wrap="nowrap" className={layoutClasses.expander}>
            <FilterVisCard title="TRUCK TYPE">
              {layoutState.loaded &&
              !truckTypeQueryState.isLoading &&
              truckTypeData ? (
                truckTypeData.payload.length > 0 ? (
                  <PieChart
                    handleSetFilter={(val) => {
                      logGAEvent("set-filter-truck-type");
                      handleSetFilter(truckTypeData.dimension, val);
                    }}
                    data={truckTypeData}
                    height={145}
                  />
                ) : (
                  <NoResultsFound />
                )
              ) : (
                <ProgressIcon isError={truckTypeQueryState.error} />
              )}
            </FilterVisCard>
            <FilterVisCard title="MILEAGE">
              {layoutState.loaded &&
              !mileageTiersQueryState.isLoading &&
              mileageTiersData ? (
                mileageTiersData.payload.length > 0 ? (
                  <ColumnChart
                    data={mileageTiersData}
                    handleSetFilter={(val) => {
                      logGAEvent("set-filter-mileage");
                      handleSetFilter(mileageTiersData.dimension, val);
                    }}
                    height={145}
                  />
                ) : (
                  <NoResultsFound />
                )
              ) : (
                <ProgressIcon isError={mileageTiersQueryState.error} />
              )}
            </FilterVisCard>
            <FilterVisCard title="HAUL DISTANCE">
              {layoutState.loaded &&
              !haulDistanceQueryState.isLoading &&
              haulDistanceTiersData ? (
                haulDistanceTiersData.payload.length > 0 ? (
                  <ColumnChart
                    data={haulDistanceTiersData}
                    handleSetFilter={(val) => {
                      logGAEvent("set-filter-haul-distance");
                      handleSetFilter(haulDistanceTiersData.dimension, val);
                    }}
                    height={145}
                  />
                ) : (
                  <NoResultsFound />
                )
              ) : (
                <ProgressIcon isError={haulDistanceQueryState.error} />
              )}
            </FilterVisCard>
            <FilterVisForm />
          </Grid>
        </Collapse>
        {!layoutState.belowFold && (
          <Box className={classes.downPromptWrapper}>
            <ExpandMoreIcon fontSize="large" className={classes.downPrompt} />
          </Box>
        )}
      </Container>
    </div>
  );
}
