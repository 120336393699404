import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  spacing: 4,
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        "&$selected": {
          backgroundColor: "orange",
          color: "green",
        },
      },
    },
  },

  palette: {
    type: "dark",
    primary: {
      main: "#F3AA18",
      light: "#835d11af",
    },
    pageBackground: {
      main: "#2E2E2E",
    },
    error: {
      main: "#FF0000",
    },
  },

  admin: {
    palette: {
      main: "#FD4301",
      secondary: "#2256FF",
    },
  },
});
