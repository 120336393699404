import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
const ContainedButton = withStyles({
  root: {
    fontSize: "0.875rem",
    fontWeight: 500,
    padding: "6px 16px",
  },
  label: {
    textTransform: "uppercase",
  },
})(Button);

export default ContainedButton;
