import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function createData(name, date) {
  return { name, date };
}
const useStyles = makeStyles(() => {
  return {
    table: {
      backgroundColor: "black",
      borderColor: "black",
    },
  };
});
export default function DenseTable({ startDate }) {
  const classes = useStyles();

  const seedDate = moment(startDate);
  const rows = [
    createData(
      "Engine and filter change",
      seedDate.subtract(10, "days").format("MM/DD/yy")
    ),
    createData(
      "Chassis, drivetrain",
      seedDate.subtract(49, "days").format("MM/DD/yy")
    ),
    createData(
      "Air filter induction system",
      seedDate.subtract(38, "days").format("MM/DD/yy")
    ),
    createData(
      "Differential fluid level",
      seedDate.subtract(116, "days").format("MM/DD/yy")
    ),
    createData(
      "Lights and signals",
      seedDate.subtract(65, "days").format("MM/DD/yy")
    ),
  ];
  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell padding="none">Service</TableCell>
            <TableCell padding="none" align="right">
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell padding="none" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell
                padding="none"
                align="right"
                style={{ padding: "7px 0 7px 0" }}
              >
                {row.date}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
