import { map } from "lodash";
import moment from "moment";

const maintenanceExpenseParser = (queryResults) => {
  if (queryResults) {
    const { data } = queryResults;

    const DIMENSION = "parts_inventory.purchased_datetime_month";
    return {
      dimension: DIMENSION,
      valueAxisLabel: "Cost ($)",
      payload: map(data, (e) => {
        return {
          label: moment(e[DIMENSION].value).format("MMM"),
          value: e["parts_inventory.total_cost"].value,
          rendered: e["parts_inventory.total_cost"].rendered,
        };
      }),
    };
  } else {
    return null;
  }
};

export default maintenanceExpenseParser;
