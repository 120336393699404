import React from "react";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { makeStyles, Typography, Box } from "@material-ui/core";
import TooltipAlert from "./TooltipAlert";

const useStyles = makeStyles(() => ({
  root: {
    margin: "5px 0 5px 0",
    opacity: 0.7,
  },
  icon: {
    color: "#ff6464",
  },
  message: {
    color: "#ff6464",
    marginLeft: "5px",
  },
}));

const CustomError = ({
  displayText = "Missing data",
  tooltipText = "",
  tooltip = false,
}) => {
  const classes = useStyles();

  return tooltip ? (
    <TooltipAlert message={tooltipText} severity={"error"}>
      <Box display="flex" flexDirection="row" className={classes.root}>
        <Box className={classes.icon}>
          <ReportProblemOutlinedIcon />
        </Box>
        <Box>
          <Typography className={classes.message} align="right">
            {displayText}
          </Typography>
        </Box>
      </Box>
    </TooltipAlert>
  ) : (
    <Box display="flex" flexDirection="row" className={classes.root}>
      <Box className={classes.icon}>
        <ReportProblemOutlinedIcon />
      </Box>
      <Box>
        <Typography className={classes.message} align="right">
          {displayText}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomError;
