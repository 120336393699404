import { map } from "lodash";

const driverSafetyParser = (queryResults) => {
  if (queryResults) {
    const { data } = queryResults;
    const DIMENSION = "trips.trip_start_datetime_raw_quarter";
    return {
      dimension: DIMENSION,
      payload: map(data, (e) => {
        return {
          label: e[DIMENSION].rendered,
          value: e["trips.safety_rating"].value,
          rendered: e["trips.safety_rating"].value,
        };
      }),
    };
  } else {
    return null;
  }
};

export default driverSafetyParser;
