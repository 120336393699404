/* eslint-disable prettier/prettier */
import { indexOf, reduce } from "lodash";
import moment from "moment";
import turf from "turf";

// lat, lon, bearing, end time
const fleetLocationParser = (queryResults) => {
  if (queryResults) {
    const { data } = queryResults;

    const fleetLocation = reduce(
      data,
      (memo, e) => {
        const startTime = moment(
          e["trips.trip_start_datetime_raw_time"].value
        ).valueOf();
        const percentComplete = e["trips.percent_completed"].value;
        const duration = e["routes_complete.duration"].value;
        const distance = e["routes_complete.distance"].value;
        const endTime = startTime + duration * 1000;

        const geometry = JSON.parse(e["routes_complete.geometry"].value);
        const timestamps = [];
        for (let i = 0; i < geometry.coordinates.length; i++) {
          if (i === 0) {
            timestamps.push(startTime);
          } else {
            const from = turf.point(geometry.coordinates[i - 1]);
            const to = turf.point(geometry.coordinates[i]);
            const legDistance = turf.distance(from, to) * 1000;
            const legDuration = (legDistance / distance) * duration * 1000;
            timestamps.push(legDuration + timestamps[i - 1]);
          }
        }
        const currentTime = startTime + percentComplete * (duration * 1000);

        const closestTimeStamp = timestamps.reduce((a, b) => {
          let aDiff = Math.abs(a - currentTime);
          let bDiff = Math.abs(b - currentTime);

          if (aDiff == bDiff) {
            return a > b ? a : b;
          } else {
            return bDiff < aDiff ? b : a;
          }
        });

        const index = indexOf(timestamps, closestTimeStamp);

        const currentCoordinates = geometry.coordinates[index];

        let color;
        switch (e["trips.trip_status"].value) {
          case "Idling":
            color = [243, 170, 24];
            break;
          case "In Transit":
            color = [0, 204, 0];
            break;
          case "Inactive":
            color = [243, 170, 24];
            break;
          case "Stopped":
            color = [255, 0, 0];
            break;
          default:
            color = [0, 204, 0];
        }

        const datum = {
          currentCoordinates,
          driverName: e["drivers.full_name"].value,
          destAddress: e["routes_complete.dest_address"].value,
          destCity: e["routes_complete.dest_city"].value,
          destState: e["routes_complete.dest_state"].value,
          driverId: e["drivers.driver_id"].value,
          geometry: JSON.parse(e["routes_complete.geometry"].value),
          originAddress: e["routes_complete.origin_address"].value,
          originCity: e["routes_complete.origin_city"].value,
          originState: e["routes_complete.origin_state"].value,
          startTimeEpoch: startTime,
          endTimeEpoch: endTime,
          tripStatus: e["trips.trip_status"].value,
          vehicleColor: e["vehicles.color"].value,
          vehicleDetails: e["vehicles.details"].value,
          vehicleEngineModel: e["vehicles.engine_model"].value,
          vehicleEngineType: e["vehicles.engine_type"].value,
          vehicleStock: e["vehicles.stock"].value,
          vehicleTransmissionModel: e["vehicles.transmission_model"].value,
          vehicleTruckType: e["vehicles.truck_type"].value,
          vehicleId: e["vehicles.vehicle_id"].value,
          vehicleMake: e["vehicles.make"].value,
          vehicleModel: e["vehicles.model"].value,
          vehicleYear: e["vehicles.year"].value,
          message: `Driver: ${e["drivers.full_name"].value}`,
          color,
        };
        memo.push(datum);
        return memo;
      },
      []
    );
    return fleetLocation;
  } else return [];
};

export default fleetLocationParser;
