import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TruckInfoContent from "./TruckInfoContent";

import ContainedButton from "../../themes/ContainedButton";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: 10,
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: 0,
    },
    formControlLabel: {
      fontSize: "13px",
      color: theme.palette.text.secondary,
    },

    driverDetailsTableRow: {
      paddingTop: "20px",
    },
    driverNameTableCell: {
      verticalAlign: "middle",
      paddingRight: "10px",
    },
    driverDetailsTableFirstRow: {
      verticalAlign: "top",
      paddingRight: "10px",
      paddingTop: "10px",
    },
    driverDetailsTableCell: {
      verticalAlign: "top",
      paddingRight: "10px",
    },

    button: {
      position: "absolute",
      right: 15,
      bottom: 15,
    },
  };
});

const TruckInfoOverlay = (props) => {
  const { handleTruckInfoClose, truckInfo, handleShowTruckDetails } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="close"
        component="span"
        size="small"
        onClick={handleTruckInfoClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>

      <TruckInfoContent truckInfo={truckInfo} />
      <ContainedButton
        className={classes.button}
        size="small"
        variant="contained"
        color="primary"
        onClick={handleShowTruckDetails}
      >
        View Vehicle Details
      </ContainedButton>
    </div>
  );
};

export default TruckInfoOverlay;
