const truckDetailsParser = (truckDetails) => {
  if (truckDetails) {
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: truckDetails.geometry,
        },
      ],
    };
  } else {
    return [];
  }
};

export default truckDetailsParser;
