import React, { useEffect, useState } from "react";
import { lookerLogin } from "../../firebase";
import LookerContext from "../../contexts/looker-context";
import AlertContext from "../../contexts/alert-context";
import ProgressIcon from "./ProgressIcon";

const LOOKER_BASE_URL = "https://4mile.looker.com:19999";

// manages demo viewer state & tracks page views
const DemoController = ({ children }) => {
  const [isSessionAllowed, setIsSessionAllowed] = useState(false);
  const [lookerToken, setLookerToken] = useState(null);
  const [isLookerTokenLoading, setIsLookerTokenLoading] = useState(false);
  const [alertTime] = useState(new Date());
  const [isAlertFocus, setIsAlertFocus] = useState(null);
  const [isSmsDialogFocus, setIsSmsDialogFocus] = useState(false);
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [isPredictiveAlert, setIsPredictiveAlert] = useState(true);
  const [isPredictiveAlertFocus, setIsPredictiveAlertFocus] = useState(false);

  useEffect(() => {
    const fetchLookerToken = async () => {
      setIsLookerTokenLoading(true);
      const token = await lookerLogin();
      setLookerToken(token);
      setIsLookerTokenLoading(false);
    };

    if (!lookerToken && !isLookerTokenLoading) {
      fetchLookerToken();
    }

    setIsSessionAllowed(true);
  }, [lookerToken]);

  if (isSessionAllowed && lookerToken) {
    return (
      <AlertContext.Provider
        value={{
          alertTime,
          isAlertFocus,
          setIsAlertFocus,
          isSmsDialogFocus,
          setIsSmsDialogFocus,
          isSmsSent,
          setIsSmsSent,
          isPredictiveAlert,
          setIsPredictiveAlertFocus,
          isPredictiveAlertFocus,
          setIsPredictiveAlert,
        }}
      >
        <LookerContext.Provider
          value={{ baseUrl: LOOKER_BASE_URL, auth: lookerToken }}
        >
          {children}
        </LookerContext.Provider>
      </AlertContext.Provider>
    );
  } else {
    return (
      <div
        style={{
          width: "100vw",
          marginTop: "50vh",
          position: "relative",
          top: "-40px",
          left: "-40px",
        }}
      >
        <ProgressIcon />
      </div>
    );
  }
};

export default DemoController;
