import React from "react";

const ScreenWarningPage = () => {
  return (
    <p
      style={{
        marginTop: 100,
        textAlign: "center",
        width: "100%",
        color: "white",
      }}
    >
      Screen size warning error goes here.
    </p>
  );
};

export default ScreenWarningPage;
