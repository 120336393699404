import React from "react";
import { Box, Grid, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MileLogo from "../../assets/4mile-footer-logo.png";
const useStyles = makeStyles((theme) => ({
  footerContain: {
    background: "black",
    padding: theme.spacing(4, 12, 0, 12),
    position: "relative",
    zIndex: 999,
  },
  builtBy: {
    fontFamily: "Roboto",
    color: "#808080",
    fontSize: "16px",
    fontWeight: 400,
  },
  contactUs: {
    fontSize: "36px",
    fontWeight: 900,
    color: "#FFFFFF",
  },
  link: {
    position: "relative",
    top: 3,
    fontSize: "16px",
    color: "#808080",
    "&:hover": {
      color: "#ffffff",
    },
  },
  contactContain: {
    position: "relative",
    top: 15,
  },
  legal: {
    position: "relative",
    top: 15,
    color: "#FFFFFF",
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Box width="100%" height={120} className={classes.footerContain}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6" align="left" className={classes.builtBy}>
            Built by
          </Typography>
          <img src={MileLogo} />
        </Grid>
        <Grid item className={classes.contactContain}>
          <Typography variant="h2" align="center" className={classes.contactUs}>
            CONTACT US
          </Typography>

          <Link target="_blank" underline="none" href="https://www.4mile.io/">
            <Typography variant="h4" align="center" className={classes.link}>
              www.4mile.io{" "}
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Typography align="left" className={classes.legal}>
            © 4 Mile 2021 All Rights Reserved
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
