import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      right: 40,
      marginTop: theme.spacing(8),
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: 0,
    },
    item: {
      minHeight: "5px",
    },
    label: {
      paddingLeft: "9px",
      whiteSpace: "nowrap",
      minWidth: "60px",
    },
    greenDot: {
      borderColor: "rgb(0, 204, 0)",
      backgroundColor: "rgb(0, 204, 0)",
      padding: 7,
    },
    lightGreenDot: {
      borderColor: "rgb(0, 204, 0, 0.5)",
      backgroundColor: "rgb(0, 204, 0, 0.5)",
      padding: 7,
    },
    yellowDot: {
      borderColor: "rgb(243, 170, 24)",
      backgroundColor: "rgb(243, 170, 24)",
      padding: 6,
    },
    lightYellowDot: {
      borderColor: "rgb(243, 170, 24, 0.5)",
      backgroundColor: "rgb(243, 170, 24, 0.5)",
      padding: 6,
    },
    redDot: {
      borderColor: "rgb(255, 0, 0)",
      backgroundColor: "rgb(255, 0, 0)",
      padding: 6,
    },
    lightRedDot: {
      borderColor: "rgb(255, 0, 0, 0.5)",
      backgroundColor: "rgb(255, 0, 0, 0.5)",
      padding: 6,
    },
  };
});

export default function TripTimeline({ truckInfo }) {
  const classes = useStyles();
  const { endTimeEpoch, startTimeEpoch, tripStatus } = truckInfo;
  const startTimeFmt = moment(startTimeEpoch).format("h:mm a");
  const endTimeFmt = moment(endTimeEpoch).format("h:mm a");

  let middleDotClass,
    lastDotClass = null;
  switch (tripStatus) {
    case "Idling":
      middleDotClass = classes.yellowDot;
      lastDotClass = classes.lightYellowDot;
      break;
    case "In Transit":
      middleDotClass = classes.greenDot;
      lastDotClass = classes.lightGreenDot;
      break;
    case "Inactive":
      middleDotClass = classes.yellowDot;
      lastDotClass = classes.lightYellowDot;
      break;
    case "Stopped":
      middleDotClass = classes.redDot;
      lastDotClass = classes.lightRedDot;
      break;
  }

  return (
    <Timeline className={classes.root}>
      <TimelineItem className={classes.item}>
        <TimelineSeparator>
          <TimelineDot className={classes.greenDot} color="inherit" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.label}>
          {startTimeFmt}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem className={classes.item}>
        <TimelineSeparator>
          <TimelineDot className={middleDotClass} color="inherit" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.label}>
          {tripStatus}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem className={classes.item}>
        <TimelineSeparator>
          <TimelineDot className={lastDotClass} color="inherit" />
        </TimelineSeparator>
        <TimelineContent className={classes.label}>
          {endTimeFmt}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
