import firebase from "firebase/app";
import { globalHistory } from "@reach/router";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyDwtVuJjMa31lgC_5OrTj4XWAWWnf_kYio",
  authDomain: "four-mile-data-experienc-db031.firebaseapp.com",
  projectId: "four-mile-data-experienc-db031",
  storageBucket: "four-mile-data-experienc-db031.appspot.com",
  messagingSenderId: "970030833874",
  appId: "1:970030833874:web:d50f55586b5c86b8090d0b",
  measurementId: "G-0KQJFH5BVP",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
let analytics;

if (window.location.hostname === "localhost") {
  firestore.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);
} else {
  analytics = firebase.analytics();
  firebase.performance();
}

export const logGAEvent = (eventType, options = {}) => {
  if (analytics) {
    analytics.logEvent(eventType, options);
  } else {
    console.log("GA event: " + eventType);
  }
};

export const setGAUserProperties = (properties) => {
  if (analytics) {
    analytics.setUserProperties(properties);
  } else {
    console.log("GA user properties: ");
    console.log(properties);
  }
};

// Wire reach router to GA for screen views
globalHistory.listen(({ location }) => {
  if (analytics) analytics.setCurrentScreen(location.pathname);
  logGAEvent("page_view");
});

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithRedirect(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = firestore.doc(`users/${user.uid}`);
  try {
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, photoURL } = user;
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          ...additionalData,
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
  } catch (error) {
    console.error("Error reading user document", error);
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();

    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const lookerLogin = async () => {
  const call = functions.httpsCallable("lookerLogin");
  try {
    const response = await call();
    return response.data;
  } catch (error) {
    console.error("Error logging into Looker", error);
    throw new Error("Error logging into Looker. Please try again later");
  }
};

export const generateDemoEmbedUrl = async (data) => {
  const { targetUrl } = data;
  const call = functions.httpsCallable("generateDemoEmbedUrl");
  try {
    const response = await call({ targetUrl });

    return response.data;
  } catch (error) {
    console.error("Error logging into Looker", error);
    throw new Error("Error logging into Looker. Please try again later");
  }
};

export const createPageView = async ({
  inviteUuid,
  firstName,
  lastName,
  company,
  email,
  url,
}) => {
  if (!inviteUuid) return null;

  try {
    await firestore.collection(`pageViews`).add({
      inviteUuid,
      firstName,
      lastName,
      company,
      email,
      url,
      viewedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    console.error("Error adding page view", error);
  }
};

export const sendSms = async ({ phone, link }) => {
  const call = functions.httpsCallable("sendSms");
  try {
    const response = await call({ phone, link });
    return response.data;
  } catch (error) {
    console.error("Error sending SMS", error);
    throw new Error("Error sending SMS");
  }
};
