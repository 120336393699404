import React from "react";
import { makeStyles } from "@material-ui/core";
import CustomError from "./CustomError";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    margin: "10px 0 10px 0",
  },
  collapse: {
    position: "absolute",
    maxWidth: "200px",
  },
}));

const ProgressIcon = ({ scheme, isError, errorText = "Data unavailable" }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={
          isError
            ? classes.error
            : scheme === "4mile"
            ? "progress-icon-4mile"
            : "progress-icon"
        }
      >
        {isError ? (
          <CustomError displayText={errorText} />
        ) : (
          <>
            <div></div>
            <div></div>
            <div></div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProgressIcon;
