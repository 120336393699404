import React, { useMemo } from "react";

import { makeStyles, Typography, Grid } from "@material-ui/core";

import useRunInlineQuery from "../../hooks/use-run-inline-query";
import lookMLToQuery from "../../utils/lookMLToQuery";

import ProgressIcon from "../shared/ProgressIcon";

import MaintenanceLog from "./MaintenanceLog";
import ColumnChart from "../visualizations/ColumnChart";

import driverSafetyParser from "./parsers/driver-safety-parser";
import DRIVER_SAFETY_ML from "./queries/driver-safety.yaml";

const DRIVER_SAFETY_QUERY = lookMLToQuery(DRIVER_SAFETY_ML);

const useStyles = makeStyles((theme) => {
  return {
    headerText: {
      fontSize: 16,
      marginBottom: theme.spacing(2),
      textTransform: "uppercase",
      color: "#808080",
    },
    healthyVis: {
      width: "100%",
      height: "85%",
    },
  };
});

const TruckDetailsOverlayHealth = ({ truckDetails }) => {
  const { driverId, startTimeEpoch } = truckDetails;

  const mergedQuery = {
    ...DRIVER_SAFETY_QUERY,
    filters: {
      ...DRIVER_SAFETY_QUERY.filters,
      "safety.driver_id": driverId.toString(),
    },
  };

  const driverSafetyQueryState = useRunInlineQuery(mergedQuery);
  const driverSafetyData = useMemo(
    () => driverSafetyParser(driverSafetyQueryState.queryResults),
    [driverSafetyQueryState.queryResults]
  );
  const classes = useStyles();
  return (
    <Grid container item direction="row" spacing={4}>
      <Grid item xs={6}>
        <Typography
          variant="h6"
          align="left"
          color="primary"
          className={classes.headerText}
        >
          Maintenance Log
        </Typography>
        <Typography color="textSecondary" variant="body1" component="span">
          <MaintenanceLog startDate={startTimeEpoch} />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant="h6"
          align="left"
          color="primary"
          className={classes.headerText}
        >
          Driver Stats
        </Typography>
        {driverSafetyData && !driverSafetyQueryState.isLoading ? (
          driverSafetyData.payload.length > 0 ? (
            <ColumnChart height={225} data={driverSafetyData} />
          ) : (
            <Typography variant="body2" color="textSecondary" align="center">
              No results found.
            </Typography>
          )
        ) : (
          <ProgressIcon error={driverSafetyQueryState.error} />
        )}
      </Grid>
    </Grid>
  );
};

export default TruckDetailsOverlayHealth;
