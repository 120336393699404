import React, { useMemo } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import { navigate } from "@reach/router";

import LineChart from "../visualizations/LineChart";
import NoResultsFound from "../shared/NoResultsFound";

import useRunInlineQuery from "../../hooks/use-run-inline-query";
import lookMLToQuery from "../../utils/lookMLToQuery";

import FUEL_CONSUMPTION_ML from "./queries/fuel-consumption.yaml";
import fuelConsumptionParser from "./parsers/fuel-consumption-parser";
import ProgressIcon from "../shared/ProgressIcon";

import ContainedButton from "../../themes/ContainedButton";

const FUEL_CONSUMPTION_QUERY = lookMLToQuery(FUEL_CONSUMPTION_ML);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(18, 0, 18, 0),
    minWidth: "100vw",
    background: "none",
  },
  button: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(2, 10, 2, 10),
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
  },
  bold: {
    fontWeight: 900,
  },
  content: {
    padding: theme.spacing(18, 0, 12, 0),
  },
  heroTitle: {
    fontWeight: 100,
  },
  heroPara: {
    fontSize: 16,
  },
  root: {
    width: 500,
    background: "none",
    border: "none",
    color: theme.palette.primary.main,
    paddingRight: 250,
  },
  description: {
    fontSize: 12,
  },
}));

export default function FuelConsumption() {
  const fuelConsumptionState = useRunInlineQuery(FUEL_CONSUMPTION_QUERY);

  const fuelConsumptionData = useMemo(
    () => fuelConsumptionParser(fuelConsumptionState.queryResults),
    [fuelConsumptionState]
  );

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="md" className={classes.content}>
        <Typography
          variant="h3"
          align="center"
          color="textSecondary"
          className={classes.heroTitle}
          gutterBottom
        >
          Fuel Consumption
        </Typography>
        <Typography
          className={classes.heroPara}
          align="center"
          color="textSecondary"
        >
          The fuel consumption rate of the fleet has gone down steadily over the
          past few months. After replacing the engines in two of the vehicles,
          we have witnessed a decrease in fuel consumption of 3.8 miles per
          gallon (mpg).
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md">
        {fuelConsumptionData && !fuelConsumptionState.isLoading ? (
          fuelConsumptionData.payload.length > 0 ? (
            <LineChart height={550} data={fuelConsumptionData} />
          ) : (
            <NoResultsFound />
          )
        ) : (
          <ProgressIcon isError={fuelConsumptionState.error} />
        )}
      </Container>
      <Box textAlign="center">
        <ContainedButton
          onClick={() => navigate(`/reporting`)}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          View Reporting Dashboard
        </ContainedButton>
      </Box>
    </Container>
  );
}
