import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    background: "none",
    border: "none",
    color: theme.palette.primary.main,
    overflow: "visible",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  pos: {
    marginBottom: 12,
  },
  box: {
    marginLeft: "-50px",
  },
}));

export default function FilterVisCard(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <Card className={classes.root} variant="outlined">
      <Typography className={classes.title}>{props.title}</Typography>
      <Box width={"100%"} height={"100%"} className={classes.box}>
        {children}
      </Box>
    </Card>
  );
}
