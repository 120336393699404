import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";

import DeckGL from "@deck.gl/react";
import { FlyToInterpolator } from "@deck.gl/core";
import { GeoJsonLayer, ScatterplotLayer, IconLayer } from "@deck.gl/layers";
import { TripsLayer } from "@deck.gl/geo-layers";
import { makeStyles } from "@material-ui/core";
import { MapboxLayer } from "@deck.gl/mapbox";
import { StaticMap } from "react-map-gl";

import AlertContext from "../../contexts/alert-context";
import useRunInlineQuery from "../../hooks/use-run-inline-query";
import lookMLToQuery from "../../utils/lookMLToQuery";
import ProgressIcon from "../shared/ProgressIcon";

import LiveMapToggles from "./LiveMapToggles";
import TruckInfoOverlay from "./TruckInfoOverlay";
import TruckDetailsOverlay from "./TruckDetailsOverlay";
import PredictiveAnalyticsOverlay from "./PredictiveAnalyticsOverlay";
import SmsDialog from "./SmsDialog";
import { logGAEvent } from "../../firebase";

import ROUTES_QUERY_ML from "./queries/routes.yaml";
import FLEET_LOCATION_ML from "./queries/fleet-location.yaml";
import routesParser from "./parsers/routes-parser";
import fleetLocationParser from "./parsers/fleet-location-parser";
import truckDetailsParser from "./parsers/truck-details-parser";
import PROBLEM_TRUCK from "./data/problem-truck.json";

import ICON_ATLAS from "../../assets/icon-atlas.png";

const ROUTES_QUERY = lookMLToQuery(ROUTES_QUERY_ML);
const FLEET_LOCATION_QUERY = lookMLToQuery(FLEET_LOCATION_ML);

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic3RvY2tlcmRhdmlkIiwiYSI6ImNra25kOTdjZTJjMWkydW53eWN6MWcyMmUifQ.ZrwzTJhm1j2AxFhIUbcS_w";

// TO DO: REPLACE LOOP_LENGTH WITH DATA MAX ROUTE DURATION FROM LOOKER
const LOOP_LENGTH = 177508;
const ANIMATION_SPEED = 50;

const useStyles = makeStyles(() => ({
  mapContainer: {
    height: "90vh",
    width: "100vw",
    position: "absolute",
    overflow: "hidden",
    zIndex: "1",
  },
  gradientOverlay: {
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,14,17,1) 5%, rgba(0,13,16,0.25674019607843135) 32%, rgba(0,11,11,0) 100%)",
    height: "90vh",
    width: "100vw",
    position: "absolute",
    overflow: "hidden",
    pointerEvents: "none",
    zIndex: "10",
  },
  layerToggleOverlay: {
    width: "174px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    overflow: "hidden",
    top: 117,
    right: 40,
    zIndex: "10",
  },
  truckInfoOverlay: {
    width: "400px",
    height: "240px",
    backgroundColor: "rgba(0, 0, 0, 1)",
    position: "absolute",
    overflow: "hidden",
    top: 117,
    left: 40,
    zIndex: "10",
  },
  truckDetailsOverlay: {
    width: "780px",
    minHeight: "620px",
    backgroundColor: "rgba(0, 0, 0, 1)",
    position: "absolute",
    overflow: "hidden",
    top: 117,
    left: 40,
    zIndex: "10",
  },
  hide: {
    visibility: "hidden",
  },
  progressIconContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
    top: 300,
    left: 0,
    zIndex: "10",
  },
}));

const LiveMap = (props) => {
  const { truckDetails, setTruckDetails } = props;
  const [glContext, setGLContext] = useState();
  const [time, setTime] = useState(0);
  const [animation] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isTripsVisible, setIsTripsVisible] = useState(true);
  const [isRoutesVisible, setIsRoutesVisibile] = useState(true);
  const [isFleetLocationVisible, setIsFleetLocationVisible] = useState(false);
  const [truckInfo, setTruckInfo] = useState(null);
  const alertContext = useContext(AlertContext);
  const {
    isAlertFocus,
    setIsAlertFocus,
    isPredictiveAlertFocus,
    setIsPredictiveAlertFocus,
    setIsPredictiveAlert,
    setIsSmsDialogFocus,
    setIsSmsSent,
  } = alertContext;

  const routesQueryState = useRunInlineQuery(ROUTES_QUERY);
  const fleetLocationQueryState = useRunInlineQuery(FLEET_LOCATION_QUERY);

  const isError =
    ((isTripsVisible || isRoutesVisible) && routesQueryState.error) ||
    (isFleetLocationVisible && fleetLocationQueryState.error);

  const isLoading =
    ((isTripsVisible || isRoutesVisible) && routesQueryState.isLoading) ||
    (isFleetLocationVisible && fleetLocationQueryState.isLoading);

  const routesData = useMemo(
    () => routesParser(routesQueryState.queryResults, LOOP_LENGTH),
    [routesQueryState.queryResults]
  );

  const fleetLocationData = useMemo(
    () => fleetLocationParser(fleetLocationQueryState.queryResults),
    [fleetLocationQueryState.queryResults]
  );

  useEffect(() => {
    if (isAlertFocus) {
      handleSetIsFleetLocationVisible(true);
      setTruckDetails(PROBLEM_TRUCK[0]);
    }
  }, [isAlertFocus]);

  useEffect(() => {
    if (isAlertFocus || isFleetLocationVisible) {
      window.scrollTo(0, 0);
    }
  }, [isAlertFocus, isPredictiveAlertFocus]);

  const truckDetailsData = truckDetailsParser(truckDetails);
  const zoomCoordinates = truckDetails && truckDetails.currentCoordinates;

  const initialViewState = useMemo(() => {
    return {
      longitude: zoomCoordinates ? zoomCoordinates[0] - 2 : -98.5795,
      latitude: zoomCoordinates ? zoomCoordinates[1] : 36.8283,
      zoom: zoomCoordinates ? 7 : 3.7,
      maxZoom: 7,
      minZoom: 3,
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator({
        maxDuration: 2000,
      }),
    };
  }, [zoomCoordinates]);

  const deckRef = useRef(null);
  const mapRef = useRef(null);

  const animate = () => {
    setTime((t) => {
      return (t + ANIMATION_SPEED) % LOOP_LENGTH;
    });
    animation.id = window.requestAnimationFrame(animate);
  };

  const handleSetIsFleetLocationVisible = (visible) => {
    setTruckInfo(null);
    setTruckDetails(null);

    setIsTripsVisible(!visible);
    setIsRoutesVisibile(!visible);

    setIsFleetLocationVisible(visible);
  };

  const onMapLoad = useCallback(() => {
    const map = mapRef.current.getMap();
    const deck = deckRef.current.deck;

    // You must initialize an empty deck.gl layer to prevent flashing
    map.addLayer(
      // This id has to match the id of the deck.gl layer
      new MapboxLayer({ id: "routes-layer", deck }),
      // Optionally define id from Mapbox layer stack under which to add deck layer
      "road-label"
    );
    map.addLayer(
      // This id has to match the id of the deck.gl layer
      new MapboxLayer({ id: "trips-layer", deck }),
      // Optionally define id from Mapbox layer stack under which to add deck layer
      "road-label"
    );

    map.addLayer(
      // This id has to match the id of the deck.gl layer
      new MapboxLayer({ id: "truck-details-layer", deck })
      // Optionally define id from Mapbox layer stack under which to add deck layer
    );

    map.addLayer(
      // This id has to match the id of the deck.gl layer
      new MapboxLayer({ id: "fleet-location-layer", deck })
      // Optionally define id from Mapbox layer stack under which to add deck layer
    );

    map.addLayer(
      // This id has to match the id of the deck.gl layer
      new MapboxLayer({ id: "problem-truck-layer", deck })
      // Optionally define id from Mapbox layer stack under which to add deck layer
    );

    map.addLayer(
      // This id has to match the id of the deck.gl layer
      new MapboxLayer({ id: "scatterplot-layer", deck }),
      // Optionally define id from Mapbox layer stack under which to add deck layer
      "road-label"
    );
    animation.id = window.requestAnimationFrame(animate);
    setIsVisible(true);
    return () => window.cancelAnimationFrame(animation.id);
  }, [animation]);

  const routesLayer = new GeoJsonLayer({
    id: "routes-layer",
    data: routesData,
    extruded: false,
    filled: true,
    lineJointRounded: true,

    /* props from PathLayer class */

    // billboard: false,
    getLineColor: (d) => d.color,
    getLineWidth: 2,
    lineWidthMinPixels: 2,
    lineWidthScale: 1,
    // miterLimit: 4,
    rounded: true,
    // widthMaxPixels: Number.MAX_SAFE_INTEGER,
    // widthMinPixels: 1,
    // widthScale: 20,
    // widthUnits: 'meters',

    /* props inherited from Layer class */

    // autoHighlight: false,
    // coordinateOrigin: [0, 0, 0],
    // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    // highlightColor: [0, 0, 128, 128],
    // modelMatrix: null,
    opacity: 0.7,
    parameters: {
      depthMask: false,
    },
    pickable: true,
    visible: isRoutesVisible && !isAlertFocus,
  });

  const truckDetailsLayer = new GeoJsonLayer({
    id: "truck-details-layer",
    data: truckDetailsData,
    extruded: false,
    filled: true,
    getLineColor: [0, 204, 0],
    getLineWidth: 2,
    lineWidthMinPixels: 2,
    lineWidthScale: 1,
    rounded: true,
    lineJointRounded: true,
    widthMinPixels: 1,
    widthScale: 20,
    opacity: 0.3,
    parameters: {
      depthMask: false,
    },
    pickable: false,
    visible: !!truckDetails,
  });

  const scatterPlotLayer = new ScatterplotLayer({
    id: "scatterplot-layer",
    data: routesData.features,
    pickable: false,
    opacity: 1,
    stroked: true,
    filled: true,
    radiusScale: 6,
    radiusMinPixels: 7,
    radiusMaxPixels: 7,
    lineWidthMinPixels: 1,
    getPosition: (d) => {
      return [
        d.properties["routes_complete.origin_lon"].value,
        d.properties["routes_complete.origin_lat"].value,
      ];
    },
    getFillColor: [243, 170, 24],
    getLineColor: [0, 0, 0],
  });

  const tripsLayer = new TripsLayer({
    id: "trips-layer",
    data: routesData.features,
    getPath: (d) => {
      return d.geometry.coordinates;
    },
    getTimestamps: (d) => d.timestamps,
    getColor: [255, 170, 0],
    opacity: 1,
    widthMinPixels: 5,
    rounded: true,
    trailLength: 6000,
    currentTime: time,
    visible: isTripsVisible && !isAlertFocus,
    pickable: false,
  });

  const fleetLocationLayer = new IconLayer({
    id: "fleet-location-layer",
    data: fleetLocationData,

    /* props from IconLayer class */

    // alphaCutoff: 0.05,
    // billboard: true,
    // getAngle: 0,
    getColor: (d) => d.color,
    getIcon: () => "marker",
    // getPixelOffset: [0, 0],
    getPosition: (d) => d.currentCoordinates,
    getSize: 5,
    iconAtlas: ICON_ATLAS,
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
        mask: true,
      },
    },
    // sizeMaxPixels: Number.MAX_SAFE_INTEGER,
    // sizeMinPixels: 0,
    sizeScale: 8,
    // sizeUnits: 'pixels',

    /* props inherited from Layer class */

    // autoHighlight: false,
    // coordinateOrigin: [0, 0, 0],
    // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    // highlightColor: [0, 0, 128, 128],
    // modelMatrix: null,
    // opacity: 1,
    pickable: true,
    visible: isFleetLocationVisible && !isAlertFocus,
    onClick: ({ object }) => {
      logGAEvent("open-truck-info-summary", {});
      setTruckInfo(object);
    },
    // wrapLongitude: false,
  });

  const problemTruckLayer = new IconLayer({
    id: "problem-truck-layer",
    data: PROBLEM_TRUCK,

    /* props from IconLayer class */

    // alphaCutoff: 0.05,
    // billboard: true,
    // getAngle: 0,
    // getColor: (d) => d.color,
    getIcon: (d) => d.icon,
    // getPixelOffset: [0, 0],
    getPosition: (d) => d.currentCoordinates,
    getSize: 5,
    iconAtlas: ICON_ATLAS,
    //      "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
    iconMapping: {
      truck: {
        x: 0,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
        mask: false,
      },
      swap: {
        x: 128,
        y: 0,
        width: 85,
        height: 128,
        anchorY: 128,
        mask: false,
      },
    },
    // sizeMaxPixels: Number.MAX_SAFE_INTEGER,
    // sizeMinPixels: 0,
    sizeScale: 8,
    // sizeUnits: 'pixels',

    /* props inherited from Layer class */

    // autoHighlight: false,
    // coordinateOrigin: [0, 0, 0],
    // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    // highlightColor: [0, 0, 128, 128],
    // modelMatrix: null,
    // opacity: 1,
    pickable: true,
    visible: isAlertFocus,
    // wrapLongitude: false,
  });

  const classes = useStyles();

  const map = useMemo(
    () => (
      <DeckGL
        id="deck-gl"
        ref={deckRef}
        onWebGLInitialized={setGLContext}
        className={classes.deckGLWrapper}
        glOptions={{
          /* To render vector tile polygons correctly */
          stencil: true,
        }}
        controller={true}
        getTooltip={({ object }) => object && object.message}
        getCursor={({ isDragging, isHovering }) =>
          isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
        }
        initialViewState={initialViewState}
        layers={[
          scatterPlotLayer,
          routesLayer,
          tripsLayer,
          truckDetailsLayer,
          fleetLocationLayer,
          problemTruckLayer,
        ]}
      >
        {glContext && (
          /* This is important: Mapbox must be instantiated after the WebGLContext is available */

          <StaticMap
            ref={mapRef}
            gl={glContext}
            mapStyle="mapbox://styles/stockerdavid/ckk5xsqia068i17nwl2sw6spu"
            mapboxApiAccessToken={MAPBOX_TOKEN}
            onLoad={onMapLoad}
          />
        )}
      </DeckGL>
    ),
    [
      scatterPlotLayer,
      routesLayer,
      tripsLayer,
      truckDetailsLayer,
      fleetLocationLayer,
      problemTruckLayer,
    ]
  );

  return (
    <>
      <div className={classes.gradientOverlay}></div>
      {(isLoading || isError) && (
        <div className={classes.progressIconContainer}>
          <ProgressIcon isError={isError} />
        </div>
      )}
      {!isAlertFocus && !isPredictiveAlertFocus && (
        <div className={classes.layerToggleOverlay}>
          <LiveMapToggles
            isTripsVisible={isTripsVisible}
            setIsTripsVisible={setIsTripsVisible}
            isRoutesVisible={isRoutesVisible}
            setIsRoutesVisibile={setIsRoutesVisibile}
            isFleetLocationVisible={isFleetLocationVisible}
            setIsFleetLocationVisible={handleSetIsFleetLocationVisible}
          />
        </div>
      )}
      {!!truckInfo && (
        <div className={classes.truckInfoOverlay}>
          <TruckInfoOverlay
            truckInfo={truckInfo}
            handleTruckInfoClose={() => setTruckInfo(null)}
            handleShowTruckDetails={() => {
              logGAEvent("open-truck-info-details", {});
              setTruckDetails(truckInfo);
              setTruckInfo(null);
            }}
          />
        </div>
      )}
      {!!truckDetails && (
        <div className={classes.truckDetailsOverlay}>
          <TruckDetailsOverlay
            truckDetails={truckDetails}
            problemTruck={PROBLEM_TRUCK[0]}
            handleTruckDetailsClose={() => {
              setTruckDetails(null);
              setIsAlertFocus(false);
            }}
          />
        </div>
      )}
      {isPredictiveAlertFocus && (
        <div className={classes.truckDetailsOverlay}>
          <PredictiveAnalyticsOverlay
            handleResolvePredictiveAlert={() => {
              setIsPredictiveAlert(false);
              setIsPredictiveAlertFocus(false);
            }}
            handleClose={() => {
              setIsPredictiveAlertFocus(false);
            }}
          />
        </div>
      )}
      <SmsDialog
        problemTruck={PROBLEM_TRUCK[0]}
        handleSendClose={() => {
          setTruckDetails(null);
          setIsAlertFocus(false);
          setIsSmsDialogFocus(false);
          setIsSmsSent(true);
        }}
      />
      <div
        className={`${classes.mapContainer} ${isVisible ? "" : classes.hide}`}
      >
        {map}
      </div>
    </>
  );
};

export default LiveMap;
