import { useState, useEffect, useContext, useMemo } from "react";
import LookerContext from "../contexts/looker-context";
import FilterContext from "../contexts/filter-context";

// TODO: read this from context exposed by LookerProvider

export function runInlineQuery(query, looker, options) {
  const { baseUrl, auth } = looker;
  const { type } = options;
  return fetch(`${baseUrl}/api/4.0/queries/run/${type}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${auth.token_type} ${auth.access_token}`,
    },
    body: JSON.stringify(query),
  }).then((response) => {
    const resp = response.json();
    return resp;
  });
}

export default function useRunInlineQuery(
  query,
  options = { type: "json_detail" }
) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [queryResults, setQueryResults] = useState(null);
  const lookerContext = useContext(LookerContext);
  const filterContext = useContext(FilterContext);
  const mergedQuery = useMemo(() => {
    return {
      ...query,
      filters: { ...query.filters, ...filterContext.filters },
    };
  }, [filterContext.filters]);

  useEffect(() => {
    async function executeRunInlineQuery() {
      setIsLoading(true);
      try {
        if (mergedQuery) {
          const query_results = await runInlineQuery(
            mergedQuery,
            lookerContext,
            options
          );
          setQueryResults({ ...query_results });
          setIsLoading(false);
        }
      } catch (e) {
        setError(e);
        setIsLoading(false);
      }
    }
    executeRunInlineQuery();
  }, [mergedQuery]);

  return { queryResults, error, isLoading };
}
