import React, { useLayoutEffect, useState, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const ColumnChart = (props) => {
  const chartNode = useRef(null);
  const [name] = useState((Math.random() * 10000).toString());
  const { height, data, handleSetFilter } = props;

  const { payload, valueAxisLabel } = data;

  useLayoutEffect(() => {
    // Create chart instance
    var chart = am4core.create(name, am4charts.XYChart);
    chart.data = payload;

    chart.colors.list = [am4core.color("#F3AA18")];

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fill = "#A3A3A3";
    categoryAxis.renderer.labels.template.fontSize = 10;

    // categoryAxis.renderer.labels.template.adapter.add(
    //   "dy",
    //   function (dy, target) {
    //     if (target.dataItem && target.dataItem.index & (2 == 2)) {
    //       return dy + 25;
    //     }
    //     return dy;
    //   }
    // );

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fill = "#A3A3A3";
    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.grid.template.stroke = "#F0F0F0";
    valueAxis.title.text = valueAxisLabel;
    valueAxis.title.fill = "#A3A3A3";
    valueAxis.title.fontSize = 10;
    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "label";
    series.name = "Litres";
    series.columns.template.tooltipText = "[bold]{rendered}[/]";
    series.columns.template.fillOpacity = 1;
    series.columns.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];
    if (handleSetFilter) {
      series.columns.template.events.on("hit", (event) => {
        handleSetFilter(event.target.dataItem.categoryX);
      });
    }

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;

    chartNode.current = chart;
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  useLayoutEffect(() => {
    chartNode.current.data = payload;
  }, [payload]);

  return <div id={name} style={{ height }} />;
};

export default ColumnChart;
