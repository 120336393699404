/* eslint-disable prettier/prettier */
import { map, round } from "lodash";
import moment from "moment";

const totalDeliveriesParser = (queryResults) => {
  if (queryResults) {
    const { data, totals_data } = queryResults;

    const DIMENSION = "trips.trip_start_datetime_raw_week";
    return {
      dimension: DIMENSION,
      total: totals_data["trips.count"]
        ? round(totals_data["trips.count"].value)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0,
      payload: map(data, (e) => {
        return {
          label: moment(e[DIMENSION].value).toDate(),
          value: e["trips.count"].value,
          rendered: e["trips.count"].value,
        };
      }),
    };
  } else {
    return null;
  }
};

export default totalDeliveriesParser;
