import React, { useMemo } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { navigate } from "@reach/router";

import ProgressIcon from "../shared/ProgressIcon";

import ColumnChart from "../visualizations/ColumnChart";
import NoResultsFound from "../shared/NoResultsFound";

import useRunInlineQuery from "../../hooks/use-run-inline-query";
import lookMLToQuery from "../../utils/lookMLToQuery";

import MAINETENANCE_EXPENSE_ML from "./queries/maintenance-expense.yaml";
import fuelConsumptionParser from "./parsers/maintenance-expense-parser";

import ContainedButton from "../../themes/ContainedButton";

const MAINETENANCE_EXPENSE_QUERY = lookMLToQuery(MAINETENANCE_EXPENSE_ML);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "black",
    padding: theme.spacing(18, 0, 18, 0),
    minWidth: "100vw",
  },
  button: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(2, 10, 2, 10),
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
  },

  box: {
    background: theme.palette.primary.main,
  },
  content: {
    padding: theme.spacing(18, 0, 12, 0),
  },
  heroTitle: {
    fontWeight: 100,
  },
  heroPara: {
    fontSize: 16,
  },
}));

export default function Maintenance() {
  const maintenanceExpenseState = useRunInlineQuery(MAINETENANCE_EXPENSE_QUERY);
  const maintenanceExpenseData = useMemo(
    () => fuelConsumptionParser(maintenanceExpenseState.queryResults),
    [maintenanceExpenseState]
  );

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <CssBaseline />

      {/* Hero unit */}
      <Container maxWidth="md" className={classes.content}>
        <Typography
          variant="h3"
          align="left"
          color="textSecondary"
          className={classes.heroTitle}
          gutterBottom
        >
          Maintenance
        </Typography>
        <Typography className={classes.heroPara} color="textSecondary">
          Maintenance expenses are the biggest drivers of Breakdown Costs. At
          present, our maintenance budget (or percent of sales) and what is the
          proposed % for the coming year have us on track to meet our goals. The
          cost-per-repair breakdown by vehicle type, age, and mileage thresholds
          have dramatically improved since adding IoT parts sensors to the
          fleet.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md">
        {maintenanceExpenseData && !maintenanceExpenseState.isLoading ? (
          maintenanceExpenseData.payload.length > 0 ? (
            <ColumnChart data={maintenanceExpenseData} height={550} />
          ) : (
            <NoResultsFound />
          )
        ) : (
          <ProgressIcon isError={maintenanceExpenseState.error} />
        )}
      </Container>
      <Box textAlign="center">
        <ContainedButton
          onClick={() => navigate(`/explore`)}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Explore In Looker
        </ContainedButton>
      </Box>
    </Container>
  );
}
