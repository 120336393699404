import React, { useEffect, useState, useContext } from "react";
import { compact, isEmpty } from "lodash";
import { Grid, makeStyles } from "@material-ui/core";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import LiveMap from "./fleet/LiveMap";
import FleetOverview from "./fleet/FleetOverview";
import FilterVis from "./filterVis/FilterVis";
import FuelConsumption from "./fleet/FuelConsumption";

import Maintenance from "./fleet/Maintenance";
import Footer from "./shared/Footer";

import FilterContext from "../contexts/filter-context";
import AlertContext from "../contexts/alert-context";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
  },
  viewspace: {
    height: "60vh",
    pointerEvents: "none",
  },
  filters: {
    zIndex: "100",
  },
  parallaxWrapper: {
    overflow: "hidden",
    position: "relative",
  },
  parallaxContent: {
    position: "relative",
    height: "200%",
    top: "100%",
    opacity: 0,
  },
  greyBg: {
    background: theme.palette.pageBackground.main,
  },
}));

const FleetDashboardPage = () => {
  const [filters, setFilters] = useState({});
  const [truckDetails, setTruckDetails] = useState(null);
  const alertContext = useContext(AlertContext);
  const { isPredictiveAlertFocus, isAlertFocus } = alertContext;

  const showFooter = !truckDetails && !isAlertFocus && !isPredictiveAlertFocus;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetFilter = (key, val) => {
    setFilters((filters) => {
      const newFilters = { ...filters };
      if (isEmpty(val) && newFilters[key]) {
        delete newFilters[key];
      } else {
        newFilters[key] = newFilters[key] ? newFilters[key].split(",") : [];
        var index = newFilters[key].findIndex((x) => x == val);
        if (index === -1) {
          newFilters[key] = [val];
        } else {
          newFilters[key] = [];
        }
        newFilters[key] = compact(newFilters[key]).join(",");
      }
      return newFilters;
    });
  };

  const handleClearFilters = () => {
    setFilters({});
  };

  const classes = useStyles();

  const handleFiltersOnScroll = (progress, event) => (
    <div
      className={classes.filters}
      style={
        truckDetails || isPredictiveAlertFocus ? { visibility: "hidden" } : {}
      }
    >
      <FilterVis scrollProgress={progress} scrollEvent={event} />
    </div>
  );

  const tweenWrapper = (module, size, bgColor) => {
    if (truckDetails || isPredictiveAlertFocus) {
      return <div style={{ display: "none" }} />;
    }
    return (
      <Timeline
        wrapper={
          <div
            style={{ height: `${size}` }}
            className={`${classes.parallaxWrapper} ${
              bgColor === "grey" ? classes.greyBg : ""
            }`}
          />
        }
      >
        <Tween
          position="0"
          from={{
            top: "25%",
            opacity: "0",
          }}
          to={{
            top: "0%",
            opacity: "1",
          }}
        >
          <div className={classes.parallaxContent}>{module}</div>
        </Tween>
      </Timeline>
    );
  };

  return (
    <FilterContext.Provider
      value={{ filters, handleSetFilter, handleClearFilters }}
    >
      <Grid container direction="column" className={classes.container}>
        <LiveMap
          truckDetails={truckDetails}
          setTruckDetails={setTruckDetails}
        />
        <Controller>
          <Scene>
            <div className={classes.viewspace} />
          </Scene>
          <Scene pin={true} triggerHook={0}>
            {handleFiltersOnScroll}
          </Scene>
          <Scene duration="75%" triggerHook="onEnter">
            {/* <FleetOverview /> */}
            {tweenWrapper(<FleetOverview />, "600px")}
          </Scene>
          <Scene duration="85%" triggerHook="onEnter">
            {tweenWrapper(<FuelConsumption />, "1200px", "grey")}
          </Scene>
          <Scene duration="85%" triggerHook="onEnter">
            {tweenWrapper(<Maintenance />, "1200px")}
          </Scene>
        </Controller>
        {showFooter && <Footer />}
      </Grid>
    </FilterContext.Provider>
  );
};

export default FleetDashboardPage;
