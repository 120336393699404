import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const PieChart = (props) => {
  const chartNode = useRef(null);
  const [name] = useState((Math.random() * 10000).toString());
  const { height, data, handleSetFilter } = props;

  const { payload } = data;

  useLayoutEffect(() => {
    // Create chart instance
    var chart = am4core.create(name, am4charts.PieChart);
    chart.data = payload;

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "label";

    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(55);

    pieSeries.slices.template.strokeWidth = 0;
    pieSeries.slices.template.strokeOpacity = 1;
    // change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: "cursor",
        value: "pointer",
      },
    ];
    if (handleSetFilter) {
      pieSeries.slices.template.events.on("hit", (event) => {
        handleSetFilter(event.target.dataItem.category);
      });
    }

    pieSeries.colors.list = [
      am4core.color("#F3AA18"),
      am4core.color("#00CC00"),
      am4core.color("#FF0000"),
    ];

    // pieSeries.alignLabels = false;
    // pieSeries.labels.template.fill = "white";
    // pieSeries.labels.template.fontSize = 9;
    // pieSeries.labels.template.bent = true;
    // pieSeries.labels.template.radius = 3;
    // pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;

    // Create a base filter effect (as if it's not there) for the hover to return to
    var shadow = pieSeries.slices.template.filters.push(
      new am4core.DropShadowFilter()
    );
    shadow.opacity = 0;

    // Create hover state
    var hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

    // Slightly shift the shadow and make it more prominent on hover
    var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    // Add a legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "right";
    // chart.legend.maxWidth = 50;
    // chart.legend.markers.template.width = 20;
    // chart.legend.markers.template.height = 20;
    // chart.legend.labels.template.fill = "white";

    chartNode.current = chart;
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (chartNode.current) {
      chartNode.current.data = payload;
    }
  }, [payload]);

  return <div id={name} style={{ height }} />;
};

export default PieChart;
