import React, { useContext } from "react";
import moment from "moment";
import { IconButton, makeStyles, Typography, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { capitalize } from "lodash";
import TruckInfoContent from "./TruckInfoContent";
import AlertContext from "../../contexts/alert-context";

import TruckImage from "../../assets/animated-truck-healthy.gif";
import AnimatedTruckEngineImage from "../../assets/vehicle-detail-partfailfast.gif";
import TruckDetailsOverlayAlerting from "./TruckDetailsOverlayAlerting";
import TruckDetailsOverlayHealthy from "./TruckDetailsOverlayHealthy";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 40,
      marginRight: 24,
      marginLeft: 24,
      marginBottom: 40,
      color: "white",
      height: "400px",
    },
    closeButton: {
      float: "right",
    },
    importantIcon: {
      margin: theme.spacing(0, 2, 0, -2),
      fontSize: 26,
    },
    formControlLabel: {
      fontSize: "13px",
      color: theme.palette.text.secondary,
    },

    driverDetailsTableRow: {
      paddingTop: "20px",
    },
    driverNameTableCell: {
      verticalAlign: "middle",
      paddingRight: "10px",
    },
    driverDetailsTableFirstRow: {
      verticalAlign: "top",
      paddingRight: "10px",
      paddingTop: "10px",
    },
    driverDetailsTableCell: {
      verticalAlign: "top",
      paddingRight: "10px",
    },
    headshot: {
      width: "45px",
      height: "34px",
      overflow: "hidden",
    },
    header: {
      backgroundColor: "#3A3A3A",
      marginTop: -40,
      marginRight: -24,
      marginLeft: -24,
      marginBottom: 20,
      paddingTop: 5,
      paddingLeft: 24,
      paddingRight: 10,
      height: 42,
    },
    alertHeader: {
      backgroundColor: "#FF0000",
      marginTop: -40,
      marginRight: -24,
      marginLeft: -24,
      marginBottom: 20,
      paddingTop: 5,
      paddingLeft: 24,
      paddingRight: 10,
      height: 42,
    },
    button: {
      position: "absolute",
      right: 15,
      bottom: 15,
    },
    headerText: {
      fontSize: 16,
      fontWeight: 900,
      color: "#808080",
      marginBottom: theme.spacing(2),
      textTransform: "uppercase",
    },

    keyFactsWrapper: {
      marginRight: "10px",
      display: "inline",
      color: "#808080",
    },
    headerSecondaryText: {
      fontSize: 16,
      fontWeight: 700,
      color: "#808080",
      marginBottom: theme.spacing(-2),
      textTransform: "uppercase",
    },
    alertTitle: {
      fontSize: 14,
    },
    lighterText: {
      fontWeight: 300,
    },
    messageButton: {
      marginTop: theme.spacing(3),
    },
  };
});
// Stock: 40N293545M Mileage: 293656 Color: Blue Engine Model: M-83B Transmission Model: FRO 16210C

const TruckDetailsOverlay = (props) => {
  const { handleTruckDetailsClose, truckDetails } = props;
  const alertContext = useContext(AlertContext);
  const { alertTime, isAlertFocus } = alertContext;

  const classes = useStyles();

  const isAlerting = isAlertFocus;

  const {
    vehicleYear = "N/A",
    vehicleModel = "N/A",
    vehicleMake = "N/A",
    vehicleStock = "N/A",
    vehicleEngineModel = "N/A",
    vehicleColor = "N/A",
    vehicleTransmissionModel = "N/A",
  } = truckDetails;

  const VehicleDetails = (
    <Grid item>
      <Grid item>
        <Typography
          variant="h6"
          align="left"
          color="primary"
          className={classes.headerText}
        >
          {vehicleYear} {vehicleMake} {vehicleModel}
        </Typography>
        <span className={classes.keyFactsWrapper}>
          <Typography color="textSecondary" variant="body1" component="span">
            <strong>Stock:&nbsp;</strong>
          </Typography>
          <Typography variant="body1" component="span">
            {vehicleStock}
          </Typography>
        </span>
        <span className={classes.keyFactsWrapper}>
          <Typography color="textSecondary" variant="body1" component="span">
            <strong>Color:&nbsp;</strong>
          </Typography>
          <Typography variant="body1" component="span">
            {capitalize(vehicleColor)}
          </Typography>
        </span>
        <span className={classes.keyFactsWrapper}>
          <Typography color="textSecondary" variant="body1" component="span">
            <strong>Engine Model:&nbsp;</strong>
          </Typography>
          <Typography variant="body1" component="span">
            {vehicleEngineModel}
          </Typography>
        </span>
        <span className={classes.keyFactsWrapper}>
          <Typography color="textSecondary" variant="body1" component="span">
            <strong>Transmission Model:&nbsp;</strong>
          </Typography>
          <Typography variant="body1" component="span">
            {vehicleTransmissionModel}
          </Typography>
        </span>
      </Grid>
    </Grid>
  );
  return (
    <div className={classes.root}>
      <div className={isAlerting ? classes.alertHeader : classes.header}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            {isAlerting ? (
              <>
                <ErrorOutlineIcon className={classes.importantIcon} />
                <strong className={classes.alertTitle}>
                  Predicted Part Failure Alert:&nbsp;
                </strong>
                <span className={classes.lighterText}>
                  {moment(alertTime).format("hh:mm a")} | Albuquerque
                </span>
              </>
            ) : (
              <strong>Vehicle details</strong>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              component="span"
              size="small"
              onClick={handleTruckDetailsClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Grid container direction="column" spacing={4}>
        <Grid item container direction="row" justify="space-evenly">
          <Grid xs={6} item>
            <img src={isAlerting ? AnimatedTruckEngineImage : TruckImage} />
          </Grid>

          <Grid xs={6} item>
            <TruckInfoContent truckInfo={truckDetails} />
          </Grid>
        </Grid>
        {!isAlerting && VehicleDetails}
        {isAlerting ? (
          <TruckDetailsOverlayAlerting truckDetails={truckDetails} />
        ) : (
          <TruckDetailsOverlayHealthy truckDetails={truckDetails} />
        )}
      </Grid>
    </div>
  );
};

export default TruckDetailsOverlay;
