import React from "react";
import { Typography } from "@material-ui/core";

const NoResultsFound = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      No results found.
      <br />
      Please adjust filters.
    </Typography>
  );
};

export default NoResultsFound;
