const initialState = {
  test: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "test_item":
      return {
        ...state,
        test: action.payload,
      };

    default:
      return state;
  }
};
