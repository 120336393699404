import { map, forEach } from "lodash";
import moment from "moment";

const fuelConsumptionParser = (queryResults) => {
  if (queryResults) {
    const { data, pivots } = queryResults;
    const DIMENSION = "fuel_consumption.trip_start_datetime_raw_month";
    const pivotKeys = map(pivots, (p) => p.key);
    return {
      dimension: DIMENSION,
      pivotKeys,
      payload: map(data, (e) => {
        const obj = {
          label: moment(e[DIMENSION].value).toDate(),
        };
        forEach(
          pivotKeys,
          (key) =>
            (obj[key] = e["fuel_consumption.total_fuel_consumed"][key].value)
        );
        return obj;
      }),
    };
  } else {
    return null;
  }
};

export default fuelConsumptionParser;
