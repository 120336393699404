import React, { useState, useEffect } from "react";

import { IconButton, makeStyles, Typography, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ReportProblemOutlined";
import ProgressIcon from "../shared/ProgressIcon";

import { logGAEvent } from "../../firebase";

import SurvivalChartImage from "../../assets/survival-chart.png";
import TwelveMonthChartImage from "../../assets/12-month-chart.png";
import AnimatedTruckPartImage from "../../assets/flux-capacitor10fps.gif";

import ContainedButton from "../../themes/ContainedButton";
const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 40,
      marginRight: 24,
      marginLeft: 24,
      marginBottom: 40,
      color: "white",
    },
    closeButton: {
      float: "right",
    },
    header: {
      backgroundColor: "#F3AA18",
      marginTop: -40,
      marginRight: -24,
      marginLeft: -24,
      marginBottom: 20,
      paddingTop: 5,
      paddingLeft: 24,
      paddingRight: 10,
      height: 42,
    },
    button: {
      position: "absolute",
      right: 15,
      bottom: 15,
    },
    keyFactsWrapper: {
      marginRight: "10px",
      display: "inline",
    },
    box: {
      backgroundColor: "#3A3A3A",
      paddingRight: "16px",
      paddingLeft: "16px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    boxTitle: {
      textTransform: "uppercase",
    },
    tensorLogo: {
      position: "relative",
      left: "54px",
    },
    bqLogo: {
      position: "relative",
      left: "34px",
    },
    redDot: {
      borderColor: "rgb(255, 0, 0)",
      backgroundColor: "rgb(255, 0, 0)",
      width: "10px",
      height: "10px",
      display: "inline-block",
    },
    yellowDot: {
      borderColor: "rgb(243, 170, 24)",
      backgroundColor: "rgb(243, 170, 24)",
      width: "10px",
      height: "10px",
      display: "inline-block",
    },
    legendLabel: {
      color: "#808080",
      fontSize: "12px",
      position: "relative",
      top: "-10px",
      left: "8px",
      paddingRight: "30px",
    },
    importantIcon: {
      margin: theme.spacing(0, 2, 0, -2),
      fontSize: 26,
    },
    partText: {
      fontSize: 12,
      fontWeight: 400,
      color: "#808080",
      textTransform: "uppercase",
      marginTop: theme.spacing(-6),
    },
    headerText: {
      fontSize: 12,
      fontWeight: 400,
      color: "#EBEBEB",
      marginBottom: theme.spacing(6),
    },
    headerLabel: {
      color: "#808080",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textTransform: "uppercase",
      fontWeight: 900,
      fontSize: 16,
    },
    chartText: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      color: "#ffffff",
    },
    chartLabel: {
      color: "#808080",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      textTransform: "uppercase",
      fontWeight: 900,
      fontSize: "16px",
      lineHeight: "18px",
    },
    buttonsGrid: {
      marginTop: "30px",
    },
  };
});

const PredictiveAnalyticsOverlay = (props) => {
  const { handleResolvePredictiveAlert, handleClose } = props;
  const [isSurvivalChartVisible, setIsSurvivalChartVisible] = useState(false);
  const [isTimeChartVisible, setIsTimeChartVisible] = useState(false);

  useEffect(() => {
    const firstTimeout = setTimeout(
      () => setIsSurvivalChartVisible(true),
      1500
    );
    const secondTimeout = setTimeout(() => setIsTimeChartVisible(true), 1900);
    return () => {
      clearTimeout(firstTimeout);
      clearTimeout(secondTimeout);
    };
  }, []);

  const classes = useStyles();

  const handleSubmit = () => {
    logGAEvent("click-approve-forecast", {});
    handleResolvePredictiveAlert();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <>
              <ErrorOutlineIcon className={classes.importantIcon} />
              <strong>Predictive analytics alert:&nbsp;</strong>
              <span>Updated inventory forecast</span>
            </>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              component="span"
              size="small"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Grid container direction="column" spacing={4}>
        <Grid item container direction="row" justify="space-evenly">
          <Grid xs={6} item>
            <img src={AnimatedTruckPartImage} />
          </Grid>
          <Grid xs={6} item>
            {isSurvivalChartVisible ? (
              <>
                <Typography
                  variant="h6"
                  align="left"
                  color="primary"
                  className={classes.chartLabel}
                >
                  Survival curve adjustment
                </Typography>
                <Typography
                  variant="h6"
                  align="left"
                  color="primary"
                  className={classes.chartText}
                >
                  Based on latest fleet sensor data, this part is predicted to
                  fail sooner in certain operating environments.
                </Typography>
                <img src={SurvivalChartImage} />
              </>
            ) : (
              <ProgressIcon />
            )}
          </Grid>
        </Grid>
        <Grid item container direction="row">
          <Grid item xs={6}>
            <Typography
              variant="h6"
              align="left"
              color="primary"
              className={classes.partText}
            >
              Flux Capacitor | Part no. fc397
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        align="left"
        color="primary"
        className={classes.headerLabel}
      >
        FY2021 Updated inventory forecast
      </Typography>
      <Typography
        variant="h6"
        align="left"
        color="primary"
        className={classes.headerText}
      >
        As a result of the survival curve adjustment, here is an updated
        inventory forecast for this part. This update increases part cost by
        $1,000. Please approve or decline the forecast update.
      </Typography>
      <Grid item>
        {isTimeChartVisible ? (
          <img src={TwelveMonthChartImage} />
        ) : (
          <ProgressIcon />
        )}
      </Grid>
      <Grid container justify="flex-end" className={classes.buttonsGrid}>
        <ContainedButton
          onClick={handleClose}
          size="small"
          variant="text"
          color="primary"
          className={classes.declineButton}
        >
          Decline
        </ContainedButton>
        <ContainedButton
          onClick={handleSubmit}
          size="small"
          variant="contained"
          color="primary"
          className={classes.initButton}
        >
          Approve Updated Forecast
        </ContainedButton>
      </Grid>
    </div>
  );
};

export default PredictiveAnalyticsOverlay;
