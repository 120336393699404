const lookMLToQuery = (lookML) => {
  const {
    name,
    model,
    explore,
    fields,
    filters,
    sorts,
    limit,
    query_timezone,
    total,
    pivots,
  } = lookML[0];
  return {
    name,
    model,
    view: explore,
    fields,
    filters,
    sorts,
    limit,
    query_timezone,
    total,
    pivots,
  };
};

export default lookMLToQuery;
