import React, { useLayoutEffect, useState, useRef } from "react";
import { forEach } from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const LineChart = (props) => {
  const chartNode = useRef(null);
  const [name] = useState((Math.random() * 10000).toString());
  const { height, data } = props;

  const { payload, pivotKeys } = data;
  // lightest to darkest blues
  const lineColors = ["#CEEEFF", "#A6CEE3", "#1F78B4", "#0B69A9"];

  useLayoutEffect(() => {
    // Create chart instance

    var chart = am4core.create(name, am4charts.XYChart);
    chart.data = payload;

    chart.paddingRight = 20;

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      timeUnit: "week",
      count: 1,
    };
    dateAxis.renderer.labels.template.fill = "#A3A3A3";
    dateAxis.renderer.labels.template.fontSize = 10;
    dateAxis.renderer.grid.template.strokeOpacity = 0;
    dateAxis.groupData = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = "Gallons";
    valueAxis.title.fill = "#A3A3A3";
    valueAxis.title.fontSize = 10;
    valueAxis.renderer.labels.template.fill = "#A3A3A3";
    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.grid.template.stroke = "#F0F0F0";

    forEach(pivotKeys, (key, i) => {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "label";
      series.dataFields.valueY = key;
      series.name = key;
      series.tooltipHTML =
        "<span style='font-size:14px; color:#000000;'><b>{name}: {valueY.value}</b></span>";
      series.tooltipText = "[#000]{valueY.value}[/]";
      series.tooltip.background.fill = am4core.color("#FFF");
      series.tooltip.getStrokeFromObject = true;
      series.tooltip.background.strokeWidth = 3;
      series.tooltip.getFillFromObject = false;
      series.fillOpacity = 0.3;
      series.stroke = lineColors[i];
      series.fill = lineColors[i];
      series.smoothing = "monotoneX";
    });
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.fill = "white";
    chart.cursor.stroke = am4core.color("#ff0000");
    chart.numberFormatter.numberFormat = "#.";

    chartNode.current = chart;
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (chartNode.current) {
      chartNode.current.data = payload;
    }
  }, [payload]);

  return <div id={name} style={{ height }} />;
};

export default LineChart;
