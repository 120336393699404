import React, { Component, createContext } from "react";
import { auth, generateUserDocument, setGAUserProperties } from "../firebase";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (userAuth) => {
      const user = await generateUserDocument(userAuth);
      if (userAuth) {
        const domain = userAuth.email.split("@")[1];
        setGAUserProperties({ user_domain: domain });
      }
      this.setState({ user, loading: false });
    });
  }

  render() {
    const { user, loading } = this.state;

    return (
      <UserContext.Provider value={{ user, loading }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
