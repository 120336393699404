import React, { useContext, useEffect } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Typography, Link } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import { navigate } from "@reach/router";

import { logGAEvent } from "../../firebase";
import AlertContext from "../../contexts/alert-context";

const StyledMenu = withStyles({
  paper: {
    // border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: { whiteSpace: "normal", width: "300px" },
}))(MenuItem);

const useStyles = makeStyles(() => {
  return {
    button: {
      marginLeft: "-4px",
    },
  };
});

export default function DropDownMenu(props) {
  const { anchorEl, open, toggleOpen } = props;
  const alertContext = useContext(AlertContext);
  const {
    setIsAlertFocus,
    setIsPredictiveAlertFocus,
    isSmsSent,
    isPredictiveAlert,
  } = alertContext;

  const activeAlerts = !isSmsSent || isPredictiveAlert;

  useEffect(() => {
    if (open) logGAEvent("open-dropdown", {});
  }, [open]);

  const handleOpenPartFailureAlert = () => {
    toggleOpen();
    !location.pathname.match(/fleet/) && navigate(`/fleet`);
    logGAEvent("open-failure-alert", {});
    setIsAlertFocus(true);
  };

  const handlePredictiveAlert = () => {
    toggleOpen();
    !location.pathname.match(/fleet/) && navigate(`/fleet`);
    logGAEvent("open-predictive-alert", {});
    setIsPredictiveAlertFocus(true);
  };

  const classes = useStyles();
  return (
    <StyledMenu
      style={{ width: "350px" }}
      id="customized-menu"
      anchorEl={anchorEl.current}
      keepMounted
      open={open}
      onClose={toggleOpen}
    >
      {!isSmsSent && (
        <StyledMenuItem
          dense
          alignItems="flex-start"
          onClick={handleOpenPartFailureAlert}
        >
          <ListItemIcon>
            <ErrorIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`Predicted critical part failure for vehicle 8`}
            secondary={
              <>
                <Button
                  onClick={handleOpenPartFailureAlert}
                  className={classes.button}
                  size="small"
                  color="primary"
                >
                  Resolve
                </Button>
              </>
            }
          />
        </StyledMenuItem>
      )}
      {isPredictiveAlert && (
        <StyledMenuItem
          dense
          alignItems="flex-start"
          onClick={handlePredictiveAlert}
        >
          <ListItemIcon>
            <WarningIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`New inventory forecast based on updated survival curve for part FC937`}
            secondary={
              <>
                <Button
                  onClick={handlePredictiveAlert}
                  className={classes.button}
                  size="small"
                  color="primary"
                >
                  Resolve
                </Button>
              </>
            }
          />
        </StyledMenuItem>
      )}
      {!activeAlerts && (
        <StyledMenuItem dense alignItems="flex-start">
          <ListItemText
            primary={
              <>
                <Typography variant="body2">
                  You have finished the demo! Feel free to continue to explore.
                  Want the power of embedded analytics for your organization?
                  <br />
                  <Link
                    href="https://4mile.io"
                    onClick={() => logGAEvent("open-4mile-site", {})}
                  >
                    Contact 4 Mile to learn more.
                  </Link>
                </Typography>
              </>
            }
          />
        </StyledMenuItem>
      )}
      <Divider />
      <StyledMenuItem
        dense
        onClick={() => {
          logGAEvent("open-4mile-site", {});
          window.open("https://4mile.io", "_blank");
        }}
      >
        {activeAlerts && <ListItemIcon></ListItemIcon>}
        <ListItemText
          primary={
            <>
              <Button
                onClick={() => {
                  logGAEvent("open-4mile-site", {});
                  window.open("https://4mile.io", "_blank");
                }}
                className={classes.button}
                size="small"
                color="primary"
              >
                About 4 Mile
              </Button>
            </>
          }
        />
      </StyledMenuItem>
      <StyledMenuItem
        dense
        onClick={() => {
          navigate(`https://www.4mile.io/view-demo`);
        }}
      >
        {activeAlerts && <ListItemIcon></ListItemIcon>}
        <ListItemText
          primary={
            <>
              <Button
                onClick={() => {
                  navigate(`https://www.4mile.io/view-demo`);
                }}
                className={classes.button}
                size="small"
                color="primary"
              >
                Log out
              </Button>
            </>
          }
        />
      </StyledMenuItem>
    </StyledMenu>
  );
}
