import React from "react";
import { makeStyles, Grid, Link, Typography } from "@material-ui/core";
import TripTimeline from "./TripTimeline";

const useStyles = makeStyles(() => {
  return {
    driverDetailsTableRow: {
      paddingTop: "20px",
    },
    driverDetailsTableFirstRow: {
      verticalAlign: "top",
      paddingTop: "10px",
      paddingBottom: "20px",
    },
    driverDetailsTableCell: {
      verticalAlign: "top",
    },
    headshot: {
      width: "45px",
      height: "34px",
      overflow: "hidden",
      paddingRight: "5px",
    },
    cardTitle: {
      color: "#AFAFAF",
    },
    link: {
      color: "#ffffff",
    },
  };
});

const TruckInfoOverlay = (props) => {
  const { truckInfo } = props;
  const {
    driverName,
    destAddress,
    destCity,
    destState,
    originAddress,
    originCity,
    originState,
    vehicleId,
  } = truckInfo;

  const classes = useStyles();

  return (
    <Grid container direction="row" justify="space-around" alignItems="center">
      <Grid item xs={8}>
        <table>
          <tbody>
            <tr>
              <td className={classes.driverNameTableCell}>
                <img
                  className={classes.headshot}
                  src={
                    process.env.PUBLIC_URL +
                    `/img/headshots/${parseInt(vehicleId) % 12}.png`
                  }
                />
              </td>
              <td className={classes.driverNameTableCell}>
                <div>
                  <Typography variant="body1" component="span">
                    <strong className={classes.cardTitle}>Driver:&nbsp;</strong>
                  </Typography>
                  <Typography variant="body1" component="span">
                    {driverName}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body1" component="span">
                    <strong className={classes.cardTitle}>
                      Vehicle ID:&nbsp;
                    </strong>
                  </Typography>
                  <Typography variant="body1" component="span">
                    {vehicleId}
                  </Typography>
                </div>
              </td>
            </tr>
            <tr className={classes.driverDetailsTableRow}>
              <td
                style={{ paddingRight: "5px" }}
                className={classes.driverDetailsTableFirstRow}
              >
                <Typography variant="body1" component="span">
                  <strong className={classes.cardTitle}>Origin:</strong>
                </Typography>
              </td>
              <td className={classes.driverDetailsTableFirstRow}>
                <Typography variant="body1" component="span">
                  <Link
                    className={classes.link}
                    href={`https://www.google.com/maps/place/${originAddress},+${originCity},+${originState}`}
                    target="_blank"
                  >
                    {originAddress}
                  </Link>
                  <br />
                  {originCity}, {originState}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.driverDetailsTableCell}>
                <Typography variant="body1" component="span">
                  <strong className={classes.cardTitle}>Dest:</strong>
                </Typography>
              </td>
              <td className={classes.driverDetailsTableCell}>
                <Typography variant="body1" component="span">
                  <Link
                    className={classes.link}
                    href={`https://www.google.com/maps/place/${destAddress},+${destCity},+${destState}`}
                    target="_blank"
                  >
                    {destAddress}
                  </Link>
                  <br />
                  {destCity}, {destState}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid item xs={4}>
        <TripTimeline truckInfo={truckInfo} />
      </Grid>
    </Grid>
  );
};

export default TruckInfoOverlay;
