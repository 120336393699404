import React from "react";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { logGAEvent } from "../../firebase";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginLeft: 20,
      marginTop: 20,
      marginBottom: 20,
    },
    title: {
      fontFamily: "Roboto Condensed",
      fontWeight: "bold",
    },
    formControlLabel: {
      fontSize: "13px",
      color: theme.palette.text.secondary,
    },
  };
});

const LiveMapToggles = (props) => {
  const {
    isRoutesVisible,
    setIsRoutesVisibile,
    isTripsVisible,
    setIsTripsVisible,
    isFleetLocationVisible,
    setIsFleetLocationVisible,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} color="textPrimary">
        LAYERS
      </Typography>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={isRoutesVisible}
                onChange={() => {
                  logGAEvent("toggle-routes");
                  setIsRoutesVisibile(!isRoutesVisible);
                }}
                name="routes"
                color="primary"
              />
            }
            label={
              <Typography className={classes.formControlLabel}>
                Revenue / Rte.
              </Typography>
            }
          />
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={isTripsVisible}
                onChange={() => {
                  logGAEvent("toggle-trips");
                  setIsTripsVisible(!isTripsVisible);
                }}
                name="trips"
                color="primary"
              />
            }
            label={
              <Typography className={classes.formControlLabel}>
                Trips Last Wk.
              </Typography>
            }
          />
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={isFleetLocationVisible}
                onChange={() => {
                  logGAEvent("toggle-fleet-location");
                  setIsFleetLocationVisible(!isFleetLocationVisible);
                }}
                name="fleet-location"
                color="primary"
              />
            }
            label={
              <Typography className={classes.formControlLabel}>
                Fleet Location
              </Typography>
            }
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default LiveMapToggles;
