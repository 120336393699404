import React, { useEffect, useState, useContext } from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import TensorflowVibeChart from "../../assets/tensorflow-vibe-chart.png";
import ProgressIcon from "../shared/ProgressIcon";
import { logGAEvent } from "../../firebase";

import AlertContext from "../../contexts/alert-context";

import ContainedButton from "../../themes/ContainedButton";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      color: "white",
    },
    headerText: {
      fontSize: 16,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      textTransform: "uppercase",
      color: "#808080",
    },
    addIcon: {
      color: "#00CC00",
    },
    buttonContainer: {
      paddingTop: "10px",
      paddingLeft: "40px",
    },
    imageContainer: {
      paddingTop: "5px",
      paddingBottom: "15px",
    },
    headerpara: {
      color: "#ffffff",
      fontSize: 14,
      marginBottom: theme.spacing(5),
    },
    logoContain: {
      paddingTop: theme.spacing(2),
    },
    logoText: {
      fontSize: 12,
      marginLeft: theme.spacing(2),
      color: "#808080",
    },
    solutionCard: {
      maxHeight: "95vh",
      background: "rgb(66, 66, 66)",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5.5),
    },
    cardContainer: {
      paddingLeft: "20px",
    },
    initButton: {
      marginTop: "10px",
    },
    headerLabel: {
      color: "#808080",
      fontSize: "16px",
      fontWeight: 900,
      marginTop: "30px",
      marginBottom: "15px",
    },
  };
});

const TruckDetailsOverlayAlerting = () => {
  const classes = useStyles();
  const [isChartVisible, setIsChartVisible] = useState(false);
  const { isSmsDialogFocus, setIsSmsDialogFocus, isSmsSent } = useContext(
    AlertContext
  );

  useEffect(() => {
    const timeout = setTimeout(() => setIsChartVisible(true), 1500);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Grid container item direction="row" spacing={4} className={classes.root}>
      <Grid item xs={6}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              variant="h2"
              align="left"
              className={classes.headerLabel}
            >
              FLUX CAPACITOR | PART NO. FC397
            </Typography>
            <Typography
              variant="h6"
              align="left"
              color="primary"
              className={classes.headerpara}
            >
              Vibration signature is out of range. Part should be replaced
              within next 3 operating hours.
            </Typography>
          </Grid>
        </Grid>

        <div className={classes.imageContainer}>
          {isChartVisible ? (
            <img src={TensorflowVibeChart} />
          ) : (
            <ProgressIcon />
          )}
        </div>
      </Grid>

      <Grid item xs={6} className={classes.solutionCard}>
        <div className={classes.cardContainer}>
          <Typography
            variant="h6"
            align="left"
            color="primary"
            className={classes.headerText}
          >
            {isSmsSent
              ? "Vehicle Replacement in Progress"
              : "Recommended Solution"}
          </Typography>
          <Typography variant="body2">
            {isSmsSent
              ? "The vehicle will rendezvous with a replacement vehicle."
              : "Swap tractors for on-time delivery of expedited load. Direct driver to recommended depot."}
          </Typography>
          <br />
          <Typography variant="body2">
            <strong>Recommended depot</strong>
          </Typography>
          <br />
          <Grid container item spacing={4} direction="row">
            <Grid item>
              <AddLocationIcon className={classes.addIcon} />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <strong>Albuquerque Logistics Hub&nbsp;</strong>
              </Typography>
              <Typography variant="body2">
                1919 Old Town Rd NW, 87104
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <ContainedButton
              onClick={() => {
                logGAEvent("open-sms-dialog", {});
                setIsSmsDialogFocus(true);
              }}
              disabled={isSmsDialogFocus || isSmsSent}
              size="small"
              variant="contained"
              color="primary"
              className={classes.initButton}
            >
              INITIATE SWAP
            </ContainedButton>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default TruckDetailsOverlayAlerting;
