import React, { useContext, useEffect } from "react";
import { Router, Redirect } from "@reach/router";
import { UserContext } from "../providers/UserProvider";
import FleetDashboardPage from "./FleetDashboardPage";
import ReportingPage from "./ReportingPage";
import ExplorePage from "./ExplorePage";
import PageLayout from "./shared/PageLayout";
import NotFound from "./NotFoundPage";
import ScreenWarningPage from "./ScreenWarningPage";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import { useSnackbar } from "notistack";
import SnackbarUtils from "../utils/snackBarUtils";

am4core.useTheme(am4themes_material);
am4core.addLicense("CH259688579");
am4core.options.queue = true;
am4core.options.onlyShowOnViewport = true;

function Application() {
  const { loading } = useContext(UserContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setSnackBar } = SnackbarUtils;

  useEffect(() => {
    setSnackBar(enqueueSnackbar, closeSnackbar);
  }, []);

  if (loading) return <></>;

  return (
    <Router primary={false}>
      <PageLayout path="/">
        <Redirect noThrow from="/" to="/fleet" />
        <Redirect noThrow from="/pbl-demo/*" to="/fleet" />
        <Redirect noThrow from="/landing/*" to="/fleet" />
        <FleetDashboardPage path="/fleet" />
        <ReportingPage path="/reporting" />
        <ExplorePage path="/explore" />
      </PageLayout>
      <ScreenWarningPage path="/warning" />
      <NotFound default />
    </Router>
  );
}

export default Application;
