import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../Styles/Components/headerMenu.css";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppsIcon from "@material-ui/icons/Apps";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExploreIcon from "@material-ui/icons/Explore";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  nav: {
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(1),
  },
  button: {
    height: 75,
    width: 90,
    flexDirection: "column",
    color: "white",
  },

  label: {
    flexDirection: "column",
    fontSize: "12px",
    fontWeight: "700",
  },
  icon: {
    fontSize: "26px !important",
    marginBottom: theme.spacing(1.5),
  },
  tab: {
    color: "white",
  },
}));

export default function HeaderMenu() {
  const getInitialIndex = () => {
    if (location.pathname.match(/fleet/)) {
      return 0;
    } else if (location.pathname.match(/reporting/)) {
      return 1;
    } else return 2;
  };

  const [value, setValue] = React.useState(getInitialIndex());

  useEffect(() => {
    const newValue = getInitialIndex();
    if (newValue !== value) setValue(newValue);
  }, [location.pathname]);

  const classes = useStyles();

  const handleTabClick = (e, route) => {
    navigate(route);
  };

  const menuItems = [
    {
      label: "Home",
      path: "fleet",
      icon: <AppsIcon className={classes.icon} />,
    },
    {
      label: "Reporting",
      path: "reporting",
      icon: <AssignmentIcon className={classes.icon} />,
    },
    {
      label: "Explore",
      path: "explore",
      icon: <ExploreIcon className={classes.icon} />,
    },
  ];
  const tabStyle = {
    default_tab: {
      color: "white",
    },
    active_tab: {
      color: "grey",
      outline: "none",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
    },
  };

  function getStyle(isActive) {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  }
  return (
    <div className={classes.nav}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon tabs example"
      >
        {menuItems.map((item, index) => {
          const route = `/${item.path}`;
          return (
            <Tab
              key={index}
              style={getStyle(value === index)}
              label={item.label}
              icon={item.icon}
              aria-label="phone"
              className={classes.tab}
              onClick={(e) => handleTabClick(e, route)}
            />
          );
        })}
      </Tabs>
    </div>
  );
}
