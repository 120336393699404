import React from "react";
import Alert from "@material-ui/lab/Alert";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    tooltip: {
      padding: 0,
    },
  },
}));

const TooltipAlert = ({ message, severity, children }) => {
  const classes = useStyles();
  const title = () => {
    return <Alert severity={severity}>{message}</Alert>;
  };

  return (
    <Tooltip title={title()} placement={"top"} className={classes.root}>
      {children}
    </Tooltip>
  );
};

export default TooltipAlert;
