import React from "react";
import Header from "./Header";

import DemoController from "./DemoController";

export default function PageLayout(props) {
  return (
    <DemoController>
      <Header />
      {props.children}
    </DemoController>
  );
}
