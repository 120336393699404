import React, { useLayoutEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const SparkLine = (props) => {
  const chartNode = useRef(null);
  const [chartId] = useState((Math.random() * 10000).toString());
  const { height, data } = props;
  const { payload } = data;

  useLayoutEffect(() => {
    const chart = am4core.create(chartId, am4charts.XYChart);

    chart.data = payload;
    chart.padding(0, 0, 0, 0);

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.cursorTooltipEnabled = false;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.cursorTooltipEnabled = false;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;

    var series = chart.series.push(new am4charts.LineSeries());
    series.tooltipText = "{label}: [bold]{rendered}";
    series.dataFields.dateX = "label";
    series.dataFields.valueY = "value";
    series.tensionX = 0.8;
    series.strokeWidth = 2;
    series.stroke = am4core.color("#1F78B4");
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#1F78B4");

    chartNode.current = chart;
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (chartNode.current) {
      chartNode.current.data = payload;
    }
  }, [payload]);

  return <div id={chartId} style={{ height }} />;
};

export default SparkLine;
