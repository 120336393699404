import { reduce } from "lodash";

const fleetSummaryParser = (queryResults) => {
  if (queryResults) {
    const { data } = queryResults;
    return reduce(
      data,
      (memo, curr) => {
        if (curr["trips.trip_status"].value === "Completed") {
          memo["available"] = curr["trips.count"].value;
        } else {
          memo["active"] += curr["trips.count"].value;
        }
        memo["drivers"] += curr["drivers.count"].value;
        return memo;
      },
      { available: 0, active: 0, drivers: 0 }
    );
  } else {
    return null;
  }
};

export default fleetSummaryParser;
