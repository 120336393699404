import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import FilterContext from "../../contexts/filter-context";
import { logGAEvent } from "../../firebase";

import CITIES from "./cities.csv";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    background: "none",
    border: "none",
    paddingBottom: 0,
    marginBottom: 0,
    marginTop: 3,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  field: {
    border: "1px solid rgba(146, 146, 144, 0.626)",
    borderRadius: "3px 3px 3px 3px",
    height: "25px",
    marginBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.main,
    width: 15,
  },
  label: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    fontSize: 16,
    fontWeight: 600,
  },
  autocomplete: {
    width: 217,
  },
  inputContainer: {
    marginBottom: 15,
  },
}));

export default function FilterVisForm() {
  const { handleSetFilter, filters } = useContext(FilterContext);
  const [origin, setOrigin] = useState(CITIES[0]);
  const [originInputValue, setOriginInputValue] = useState("");
  const [dest, setDest] = useState(CITIES[0]);
  const [destInputValue, setDestInputValue] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (!filters["routes_complete.origin_city"]) {
      setOrigin(CITIES[0]);
      setOriginInputValue("All");
    }
    if (!filters["routes_complete.dest_city"]) {
      setDest(CITIES[0]);
      setDestInputValue("All");
    }
  }, [filters]);
  return (
    <Card className={classes.root} variant="outlined">
      <div className={classes.inputContainer}>
        <InputLabel
          className={classes.label}
          htmlFor="standard-adornment-password"
        >
          BY ORIGIN
        </InputLabel>
        <Autocomplete
          size="small"
          options={CITIES}
          value={origin}
          inputValue={originInputValue}
          onInputChange={(_e, newValue) => setOriginInputValue(newValue)}
          onChange={(_e, newValue) => {
            setOrigin(newValue);
            logGAEvent("set-filter-origin-city");
            const val = newValue
              ? newValue["Routes Complete Origin City"]
              : null;
            val === "All" || val === null
              ? handleSetFilter("routes_complete.origin_city", "")
              : handleSetFilter("routes_complete.origin_city", val);
          }}
          getOptionLabel={(city) =>
            `${city["Routes Complete Origin City"]}${
              city["Routes Complete Origin State"]
                ? `, ${city["Routes Complete Origin State"]}`
                : ""
            }`
          }
          className={classes.autocomplete}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </div>
      <div className={classes.inputContainer}>
        <InputLabel
          className={classes.label}
          htmlFor="standard-adornment-password"
        >
          BY DESTINATION
        </InputLabel>
        <Autocomplete
          size="small"
          options={CITIES}
          value={dest}
          inputValue={destInputValue}
          onInputChange={(_e, newValue) => setDestInputValue(newValue)}
          onChange={(_e, newValue) => {
            setDest(newValue);
            logGAEvent("set-filter-dest-city");
            const val = newValue
              ? newValue["Routes Complete Origin City"]
              : null;
            val === "All" || val === null
              ? handleSetFilter("routes_complete.dest_city", "")
              : handleSetFilter("routes_complete.dest_city", val);
          }}
          getOptionLabel={(city) =>
            `${city["Routes Complete Origin City"]}${
              city["Routes Complete Origin State"]
                ? `, ${city["Routes Complete Origin State"]}`
                : ""
            }`
          }
          className={classes.autocomplete}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      </div>
    </Card>
  );
}
